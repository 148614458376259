@charset "utf-8";
.p-service {
    &__bg {
        position: relative;
        padding: 0 0 160/1366*100vw;
        background: linear-gradient(270deg,#E7F0F7 0,#e7f0f8 85%,#fff 50%,#fff 100%);
        margin-top: 130/1366*100vw;
        @include sp {
            padding: 0 0 200/750*100vw;
            background: linear-gradient(270deg,#E7F0F7 0,#e7f0f8 100%,#fff 50%,#fff 100%);
            margin-top: 112/750*100vw;
        }
    }
    
    &__inner{
        width: 1120/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 640/750*100vw;
        }
    }
    &__list{
        padding-top: 80/1366*100vw;
    }
    &__listItem{
        background: #fff;
        margin-top: 15/1366*100vw;
        @include sp{
            margin-top: 25/750*100vw;
        }
        a{
            display: grid;
            grid-template-columns: 400/1366*100vw 720/1366*100vw;
            @include sp{
                grid-template-columns: none;
            }
        }
        @include pc {
            &:hover {
                .p-service__leadLink {
                span:before {
                    right: 0;
                }
                }
            }
        }
    }
    
    &__img{
        @include sp{
            display: grid;
            grid-template-columns: 300/750*100vw 1fr;
        }
    }
    
    &__lead{
        padding: 30/1366*100vw 40/1366*100vw;
        @include sp{
            padding: 75/750*100vw 45/750*100vw;
        }
    }
    &__leadTtl{
        font-size: 24/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        position: relative;
        padding-left: 54/1366*100vw;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 15/1366*100vw;
        @include sp{
            @include vw(33);
            width: 280/750*100vw;
            padding-left: 0;
            margin: 120/750*100vw auto 0;
        }
        &::before{
            font-family: "Barlow";
            content: '';
            display: inline-block;
            color: #2CA6E0;
            font-size: 36/1366*100vw;
            font-weight: 700;
            vertical-align: baseline;
            position: absolute;
            left: 0;
            top: -18%;
            @include sp{
                @include vw(50);
                top: -55%;
            }
        }
        &--01{
            &::before{
                content: '01';
            }
        }
        &--02{
            &::before{
                content: '02';
            }
        }
        &--03{
            @include sp{
                margin: 100/750*100vw auto 0;
            }
            &::before{
                content: '03';
            }
        }
        &--04{
            @include sp{
                margin: 100/750*100vw auto 0;
            }
            &::before{
                content: '04';
            }
        }
    }
    
    &__leadText{
        font-size: 17/1366*100vw;
        color: #0F2C66;
        padding-top: 20/1366*100vw;
        line-height: 1.6;
        @include sp{
            @include vw(28);
            padding-top: 0;
        }
        span{
            font-size: 17/1366*100vw;
            padding-bottom: 8/1366*100vw;
            font-weight: 700;
            display: block;
            color: #0F2C66;
            @include sp{
                @include vw(28);
                padding-bottom: 25/750*100vw;
            }
        }
    }
    
    &__leadLink {
        margin-top: 20/1366*100vw;
        text-align: right;
        @include sp {
            margin-top: 45/750*100vw;
        }
        span {
            position: relative;
            color: #2CA6E0;
            font-size: 15/1366*100vw;
            font-weight: 700;
            padding-right: 40/1366*100vw;
            transition: all 0.3s;
        @include sp {
            @include vw(30);
            padding-right: 80/750*100vw;
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            right: 6/1366*100vw;
            width: 25/1366*100vw;
            height: 7/1366*100vw;
            background: url(/assets/img/top/arrow.svg);
            background-size: contain;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            transition: all 0.3s;
            @include sp {
                right: 0;
                width: 40/750*100vw;
                height: 11/750*100vw;
            }
        }
        }
    }
    
}