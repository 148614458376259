@charset "utf-8";
.p-sdgs {
    &__about {
        background: #ecf3f9;
        margin: 112/1366*100vw 0 0;
        padding: 112/1366*100vw 0 160/1366*100vw;
        @include sp {
            margin: 160/750*100vw 0 0;
            padding: 136/750*100vw 0 180/750*100vw;
        }
    }
    &__aboutImg {
        width: 360/1366*100vw;
        @include sp {
            margin: 40/750*100vw 0 0;
            width: 100%;
        }
    }
    &__aboutInner {
        background: #fff;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 64/1366*100vw 64/1366*100vw 76/1366*100vw;
        width: 972/1366*100vw;
        @include sp {
            display: block;
            padding: 56/750*100vw 44/750*100vw;
            width: 638/750*100vw;
        }
    }
    &__aboutText {
        width: 422/1366*100vw;
        @include sp {
            width: 100%;
        }
        p {
            font-size: 15/1366*100vw;
            line-height: 28/15*1;
            @include sp {
                @include vw(24);
            }
        }
    }
    &__aboutTtl {
        color: #0F2C66;
        font-size: 28/1366*100vw;
        margin: 0 0 24/1366*100vw;
        @include sp {
            @include vw(34);
        }
    }
    &__activity {
        padding: 110/1366*100vw 0;
        @include sp {
          padding: 160/750*100vw 0;
        }
    }

    // SDGS
    &__activityInner {
        width: 1053/1366*100vw;
        margin: 0 auto;
        @include sp {
            width: 638/750*100vw;
        }
    }
    &__activityTtl {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 960/1366*100vw;
        height: 64/1366*100vw;
        color: #FFF;
        font-size: 20/1366*100vw;
        font-weight: 700;
        background: #658ACC;
        margin-top: 40/1366*100vw;
        @include sp {
        width: 100%;
        height: 100/750*100vw;
        @include vw(34);
        margin-top: 40/750*100vw;
        }
    }
    &__activityBlock {
        display: flex;
        justify-content: space-between;
        margin-top: 5/1366*100vw;
        @include sp {
        display: block;
        margin-top: 10/750*100vw;
        }
    }
    &__activityNum {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160/1366*100vw;
        height: 171/1366*100vw;
        color: #FFF;
        font-size: 20/1366*100vw;
        font-weight: 700;
        background: #0F2C66;
        @include sp {
        width: 100%;
        height: 100/750*100vw;
        @include vw(34);
        }
    }


    &__activitySelect {
        align-items: center;
        background: #fff;
        color: #0F2C66;
        font-size: 20/1366*100vw;
        font-weight: 700;
        justify-content: center;
        text-align: center;
        width: 526/1366*100vw;
        @include sp {
            width: 100%;
            height: 102vw;
            @include vw(34);
        }
        div {
            position: relative;
        }
        p {
            font-size: 18/1366*100vw;
            margin: 0 0 46/1366*100vw;
            @include sp {
                @include vw(34);
            }
        }
    }
    &__activitySelectImg {
            cursor: pointer;
            left: 0;
            margin: 0 !important;
            position: absolute;
            top: 0;
        &--01 {
            top: 9vw;
            @include sp { top: 20vw; }
        }
        &--02 {
            left: 9vw;
            @include sp { left: 20vw; }
        }
        &--03 {
            left: 22.7vw;
            @include sp { left: 50vw; }
        }
        &--04 {
            left: auto;
            right: 0vw;
            top: 8.8vw;
            @include sp { top: 20vw; }
        }
        &--05 {
            left: auto;
            right: 0vw;
            top: 22.8vw;
            @include sp { top: 50vw; }
        }
        &--06 {
            bottom: 0;
            left: auto;
            right: 9vw;
            top: auto;
            @include sp { right: 20vw; }
        }
        &--07 {
            bottom: 0;
            left: 9vw;
            top: auto;
            @include sp { left: 20vw; }
        }
        &--08 {
            left: 0;
            top: 22.5vw;
            @include sp { top: 50vw; }
        }
        img {
            width: 6.7vw;
            @include sp {
                width: 113/750*100vw;
            }
            &.is-exclusion {
                opacity: 0.5;
                &.is-current {
                    opacity: 1;
                }
            }
        }
    }




  &__inner {
    position: relative;
    // padding: 0 0 160/1366*100vw;
    @include sp {
    //   padding: 0 0 200/750*100vw;
    }
  }


    // SDGS
    &__planArea {
        // background: #ECF3F9 url(/assets/img/company/sdgs/sdgs_img02.svg);
        // background: #ECF3F9;
        background: #fff;
        // border: solid 1px #000;
        height: 626/1366*100vw;
        padding: 29/1366*100vw 0 0 73/1366*100vw;
        position: relative;
        width: 482/1366*100vw;
        @include sp {
            width: 100%;
            height: auto;
            padding: 100/750*100vw 0 0;
        }
    }
    &__planCont {
        background: #ECF3F9;
        display: none;
        height: 626/1366*100vw;
        left: 0;
        position: absolute;
        top: 0;
        width: 482/1366*100vw;
        @include sp {
            height: 150vw;
            left: 50%;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 638/750*100vw;
            z-index: 101;
        }
        p {
            font-size: 15/1366*100vw;
            @include sp {
                @include vw(34);
            }
        }
        @media (orientation: landscape) and (max-width: 767px){
            top: 3%;
            height: 45vw;
            transform: translate(-50%, 0%);
        }
    }
    &__planContBg {
        background: rgba(#000,0.7);
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 3;
        @include sp {
            z-index: 100;
        }
    }
    &__planContBox {
        height: 417/1366*100vw;
        margin: 34/1366*100vw 0 0;
        padding: 0 20/1366*100vw 0 0;
        overflow: auto;
        width: 100%;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: #ECF3F9;
        }
        &::-webkit-scrollbar-thumb {
            background: #CFD3DA;
            border-radius: 2px;
        }
        @include sp {
            height: auto;
            overflow: hidden;
            margin: 34/750*100vw 0 0;
        }
    }
    &__planContBoxImg {
        margin: 64/1366*100vw 0 0;

        &--pab {
            @include sp{
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 85%;
                bottom: 7vw;
            }
            @media (orientation: landscape) and (max-width: 767px){
                bottom: auto;
                padding-bottom: 10vw;
            }
        }
    }
    &__planContBoxBlock {
        &+.p-sdgs__planContBoxBlock {
            margin: 16/1366*100vw 0 0;
        }
    }
    &__planContClose {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        z-index: 102;
        @include pc {
            width: 40/1366*100vw;
        }
        @include sp {
            position: fixed;
        }
    }
    &__planContH3 {
        font-size: 17/1366*100vw;
        font-weight: 700;
        margin: 0 0 3/1366*100vw;
        @include sp {
            margin: 0 0 16/750*100vw;
            @include vw(34);
        }
        .p-sdgs__planCont--01 & { color: #25862A; }
        .p-sdgs__planCont--02 & { color: #B7001E; }
        .p-sdgs__planCont--03 & { color: #0098D1; }
        .p-sdgs__planCont--04 & { color: #FFAD00; }
        .p-sdgs__planCont--05 & { color: #830025; }
        .p-sdgs__planCont--06 & { color: #F68E00; }
        .p-sdgs__planCont--07 & { color: #CD7D00; }
    }
    &__planContInner {
        cursor: pointer;
        position: relative;
        padding: 40/1366*100vw;
        @include sp {
            padding: 80/750*100vw 44/750*100vw;
            height: 150vw;
            overflow: auto;
        }
        @media (orientation: landscape) and (max-width: 767px){
            height: 45vw;
            
        }
    }
    &__planContTtl {
        img {
            height: 96/1366*100vw;
            width: auto;
            @include sp {
                height: 136/750*100vw;
            }
        }
    }
    &__planDefList {
        @include sp {
            width: 50%;
        }
        &+.p-sdgs__planDefList {
            margin: 12/1366*100vw 0 0;
        }
    }
    &__planDefUl {
        img {
            height: 64/1366*100vw;
            width: auto;
        }
    }
}