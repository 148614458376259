@charset "utf-8";
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  background: #fff;
  z-index: 105;
  @include sp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 110/750*100vw;
    background: #FFF;
    box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
    z-index: 6;
  }
  // &.under {
  //   background: #FFF;
  //   .l-header__logo {
  //     width: 250/1366*100vw;
  //     height: 38/1366*100vw;
  //     background: url(/assets/img/common/logo.svg);
  //     background-repeat: no-repeat;
  //     background-size: contain;
  //     @include sp {
  //       width: 420/750*100vw;
  //       height: 39/750*100vw;
  //       background: url(/assets/img/common/logo_under.svg);
  //       background-repeat: no-repeat;
  //       background-size: contain;
  //     }
  //   }
  // }
  // &.scroll {
  //   background: #FFF;
  //   box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
  //   .l-header__logo {
  //     @include pc {
  //       max-width: 250px;
  //       min-width: 220px;
  //       width: 250px;
  //       height: 28px;
  //       background: url(/assets/img/common/logo_under.svg);
  //       background-repeat: no-repeat;
  //       background-size: cover;
  //     }
  //   }
  // }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 55/1366*100vw 0 55/1366*100vw;
    box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.2);
    @include sp {
      width: 650/750*100vw;
      margin: 0 auto;
      padding: 0;
      box-shadow: none;
    }
  }
  &__logo {
    display: block;
    @include pc {
      width: 250/1366*100vw;
      height: 38/1366*100vw;
      background: url(/assets/img/common/logo.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.3s;
    }
    @include sp {
      width: 420/750*100vw;
      height: 39/750*100vw;
      background: url(/assets/img/common/logo_under.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__nav {
    position: relative;
    min-width: 460px;
    width: 820/1366*100vw;
    height: 65/1366*100vw;
    background: #FFF;
    @include sp {
      display: none
    }
  }
  &__navInner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 50%;
    left: 0;
    right: 0;
    min-width: 430px;
    width: 820/1366*100vw;
    margin: 0 auto;
    transform: translateY(-50%);
  }
  &__navItem a {
    font-size: 14/1366*100vw;
    &.contact {
      position: relative;
      padding-left: 25px;
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 0;
        width: 18px;
        height: 12px;
        background: url(/assets/img/common/header_contact.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }
  }
  &__btn {
    position: relative;
    width: 40/750*100vw;
    height: 24/750*100vw;
    cursor: pointer;
    z-index: 11;
    @include pc {
      display: none;
    }
    .borderTop {
      position: absolute;
      width: 100%;
      height: 3/750*100vw;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: $main;
      transition: all 0.3s;
    }
    .borderMiddle {
      position: absolute;
      width: 100%;
      height: 3/750*100vw;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: $main;
      border-radius: 10px;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
    .borderBottom {
      position: absolute;
      width: 100%;
      height: 3/750*100vw;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: $main;
      border-radius: 10px;
      transition: all 0.3s;
    }
    &.is-active {
      .borderTop {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
      .borderMiddle {
        top: 50%;
        transform: translateX(3vw);
        opacity: 0;
      }
      .borderBottom {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  &__menu {
    position: absolute;
    display: none;
    top: 110/750*100vw;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #FFF;
    z-index: 1;
    border-top: 1px solid rgba(0,0,0,0.2);
    @include pc {
      display: none;
    }
  }
  &__menuInner {
    padding: 150/750*100vw 0 200/750*100vw;
    @media (orientation: landscape){ // デバイスが横向きの場合のスタイルを記述
      padding: 40/750*100vw 0 100/750*100vw;
      height: 300/750*100vw;
      overflow-y: scroll;
      .l-header__menuList {
        margin-top: -2vw;
      }
      .l-header__menuListItem {
        margin-top: 5vw !important;
        a {
          @include vw(22);
        }
      }
      .l-header__menuListItem a.contact {
        padding-left: 5vw;
      }
      .l-header__menuListItem a.contact::before {
        width: 5.2;
        height: 2.5vw;
        top: 60%;
      }
    }
  }
  &__menuListItem {
    text-align: center;
    &:nth-of-type(n+2) {
      margin-top: 80/750*100vw;
    }
    a {
      color: $main;
      @include vw(34);
      font-weight: 700;
      line-height: 1.0;
      &.contact {
        position: relative;
        padding-left: 25px;
        @include sp {
          padding-left: 60/750*100vw;
        }
        &:before {
          position: absolute;
          display: block;
          content: '';
          top: 50%;
          left: 0;
          width: 18px;
          height: 12px;
          background: url(/assets/img/common/header_contact.svg);
          background-size: contain;
          background-repeat: no-repeat;
          transform: translateY(-50%);
          @include sp {
            width: 47/750*100vw;
            height: 32/750*100vw;
          }
        }
      }
    }
    
  }
}