@charset "utf-8";

.m-service{
    margin-top: 100/1366*100vw;
    
    &__intro{
        width: 960/1366*100vw;
        margin: 112/1366*100vw auto;
        background: #E7F0F8;
        @include sp{
            width: 638/750*100vw;
            margin: 135/750*100vw auto;
        }
    }
    &__introLink{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include sp{
            grid-template-columns: 1fr;
        }
        &:hover{
            .m-service__blockLink {
                span:before {
                    right: 0;
                }
            }
        }
    }
    &__introBlock{
        padding: 50/1366*100vw 50/1366*100vw;
        @include sp{
            padding: 96/750*100vw 44/750*100vw;
        }
    }
    &__introTtl{
        font-size: 22/1366*100vw;
        font-weight: bold;
        color: #0F2C66;
        @include sp{
            @include vw(36);
        }
    }
    &__introText{
        font-size: 17/1366*100vw;
        font-weight: bold;
        color: #0F2C66;
        margin-top: 25/1366*100vw;
        @include sp{
            @include vw(30);
            margin-top: 25/750*100vw;
        }
    }
    &__blockLink {
        margin-top: 40/1366*100vw;
        @include sp {
            margin-top: 60/750*100vw;
        }
        span {
            position: relative;
            color: #2CA6E0;
            font-size: 18/1366*100vw;
            font-weight: 700;
            padding-right: 60/1366*100vw;
            transition: all 0.3s;
            @include sp {
                @include vw(30);
                padding-right: 80/750*100vw;
            }
            &:before {
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                right: 6/1366*100vw;
                width: 35/1366*100vw;
                height: 10/1366*100vw;
                background: url(/assets/img/top/arrow.svg);
                background-size: cover;
                background-repeat: no-repeat;
                transform: translateY(-50%);
                transition: all 0.3s;
                @include sp {
                    right: 0;
                    width: 40/750*100vw;
                    height: 11/750*100vw;
                }
            }
            &:after {
                position: absolute;
                display: block;
                content: '';
                bottom: -10/1366*100vw;
                left: 0;
                width: 100%;
                height: 1px;
                background: #2CA6E0;
                @include sp {
                    bottom: -20/750*100vw;
                }
            }
        }
    }
    
    &__contact{
        background: #0F2C66;
    }
    &__contactInner{
        width: 960/1366*100vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 80/1366*100vw 0;
        @include sp{
            width: 638/750*100vw;
            display: block;
            padding: 112/750*100vw 0;
        }
    }
    
    &__contactTtl{
        font-size: 25/1366*100vw;
        color: #fff;
        font-weight: bold;
        @include sp{
            @include vw(36);
        }
    }
    &__contactText{
        font-size: 20/1366*100vw;
        color: #fff;
        margin-top: 32/1366*100vw;
        line-height: 2.0;
        font-weight: bold;
        @include sp{
            @include vw(28);
            margin-top: 32/750*100vw;
        }
    }
    &__contactLink{
        text-align: center;
        position: relative;
        margin-top: 70/1366*100vw;
        @include sp{
            margin-top: 90/750*100vw;
        }
        a{
            width: 322/1366*100vw;
            background: #fff;
            display: block;
            font-size: 18/1366*100vw;
            padding: 25/1366*100vw 0 25/1366*100vw 45/1366*100vw;
            @include sp{
                width: 638/750*100vw;
                @include vw(32);
                padding: 45/750*100vw 0 45/750*100vw 100/750*100vw;
            }
        }
        &::before{
            content: '';
            display: inline-block;
            background: url('../img/service/common/mail.svg')no-repeat center center;
            background-size: contain;
            width: 32/1366*100vw;
            height: 21/1366*100vw;
            position: absolute;
            top: 36%;
            left: 37/1366*100vw;
            z-index: 5;
            @include sp{
                width: 67/750*100vw;
                height: 44/750*100vw;
                left: 75/750*100vw;
            }
        }
    }
    &__solution{
        background: #E7F0F8;
        padding: 112/1366*100vw 0 160/1366*100vw 0;
        @include sp{
            padding: 150/750*100vw 0 235/750*100vw 0;
        }
    }
    &__solutionTtl{
        width: 1120/1366*100vw;
        margin: 0 auto 47/1366*100vw;
        font-weight: 700;
        text-align: center;
        color: #0F2C66;
        position: relative;
        @include sp{
            width: 638/750*100vw;
            margin: 0 auto 47/750*100vw;
        }
        &::before{
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            width: 100%;
            height: 2px;
            content: '';
            background: #E2E2E2;
            @include sp{
                background: none;
            }
        }
        span{
            position: relative;
            padding: 0 1em;
            font-size: 24/1366*100vw;
            background: #E7F0F8;
            @include sp{
                @include vw(36);
                padding: 0;
            }
        }
    }
    &__solutionList{
        width: 1120/1366*100vw;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include sp{
            width: 638/750*100vw;
            display: block;
        }
    }
    &__solutionItem{
        width: 275/1366*100vw;
        position: relative;
        counter-increment: section;
        img{
            position: relative;
            @include sp{
                width: 313/750*100vw;
            }
        }
        a{
            display: block;
            @include sp{
                display: flex;
            }
        }
        @include sp{
            width: 638/750*100vw;
            margin: 5/750*100vw 0;
        }
        @include pc{
            &:hover{
                .m-service__solutionLink{
                    &::after {
                        padding-left: 20%;
                    }
                }
            }
        }
    }
    &__solutionText{
        font-size: 22/1366*100vw;
        font-weight: bold;
        color: #0F2C66;
        padding-left: 60/1366*100vw;
        background: #fff;
        height: 88/1366*100vw;
        display: flex;
        align-items: center;
        position: relative;
        line-height: 1.3;
        @include sp{
            @include vw(32);
            width: 100vw;
            height: auto;
            padding-left: 30/750*100vw;
            line-height: 1.3;
            position: relative;
        }
        &::before{
            content: counter(section, decimal-leading-zero);
            font-family: $font-family-barlow;
            display: inline-block;
            font-size: 26/1366*100vw;
            font-weight: bold;
            color: #2CA6E0;
            position: absolute;
            top: 30%;
            left: 7%;
            @include sp{
                @include vw(38);
                top: 10%;
                left: 10%;
            }
        }
        // @include sp{
        //     &::after{
        //         content: '';
        //         display: inline-block;
        //         background: url('../img/service/common/service_arrow--sp.svg') no-repeat center center;
        //         background-size: 100%;
        //         width: 35/750*100vw;
        //         height: 15/750*100vw;
        //         bottom: 25/750*100vw;
        //         right: 30/750*100vw;
        //     }
        // }
    }
    &__solutionImg{
        position: relative;
    }
    &__solutionLink{
        @include pc{
            font-weight: bold;
            color: #fff;
            text-align: center;
            transition: all 0.3s;
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translate(-50%, -50%);
            width: 100%;
            &::after{
                content: '';
                display: inline-block;
                background: url('../img/service/common/service_arrow.svg') no-repeat center center;
                background-size: contain;
                width: 25/1366*100vw;
                height: 10/1366*100vw;
                transition: all 0.3s;
                padding-left: 15%;
                position: absolute;
                top: 25%;
            }
        }
        @include sp{
            font-weight: bold;
            color: #fff;
            text-align: center;
            transition: all 0.3s;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: 2vw;
            width: 100%;
            @include vw(25);
            &::after{
                content: '';
                display: inline-block;
                background: url('../img/service/common/service_arrow.svg') no-repeat center center;
                background-size: 100%;
                width: 35/750*100vw;
                height: 15/750*100vw;
                position: absolute;
                top: 10/750*100vw;
                right: 30/750*100vw;
            }
        }
    }
}