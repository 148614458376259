@charset "utf-8";
.l-main {
  &__wrap {
    position: relative;
    padding-bottom: 120/1366*100vw;
    background: #0F2C66;
    margin-top: 60/1366*100vw;
    @include sp {
      margin-top: 110/750*100vw;
      padding-bottom: 160/750*100vw;
    }
    .p-policy & {
      background: #fff;
      padding: 45px 0 0;
      // padding-bottom: 0/1366*100vw;
    }
  }
  &__kv {
    width: 100%;
  }
  &__breadcrumb {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    top: 20/1366*100vw;
    left: 55/1366*100vw;
    z-index: 3;
    @include sp {
      display: none;
    }
    .p-policy & {
      // position: relative;
      top: 20/1366*100vw;
    }
  }
  &__breadcrumbItem {
    &:nth-of-type(n+2) {
      position: relative;
      padding-left: 20px;
      margin-left: 10px;
      &:before {
        position: absolute;
        display: block;
        content: '＞';
        top: 50%;
        left: 0;
        color: #FFF;
        font-size: 13px;
        line-height: 1.0;
        transform: translateY(-50%);
        .p-policy & {
          color: #000;
        }
      }
    }
    &:last-of-type {
      a {
        pointer-events: none;
      }
    }
    a {
      color: #FFF;
      font-size: 13px;
      line-height: 1.0;
      .p-policy & {
        color: #000;
      }
    }
    &.gray{
      a{
        color: #808080;
      }
      &::before{
        color: #808080;
      }
    }
  }
  &__ttl {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    width: 690/1366*100vw;
    height: 241/1366*100vw;
    background: #FFF;
    &.foodFactory{
      @include sp{
        padding: 0 65/750*100vw 0 100/750*100vw;
      }
    }
    @include sp {
      justify-content: left;
      align-items: center;
      width: 586/750*100vw;
      height: 310/750*100vw;
      padding: 0 65/750*100vw;
    }
    &--en {
      display: block;
      color: #2CA6E0;
      font-size: 24/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.01em;
      @include sp {
        @include vw(32);
      }
    }
    &--ja {
      display: block;
      color: #0F2C66;
      font-size: 32/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.05em;
      margin-top: 10/1366*10vw;
      @include sp {
        @include vw(44);
        margin-top: 10/750*10vw;
      }
      &.indent{
        padding-left: 1em;
      }
    }
    .p-policy & {
      padding: 5px 0 0;
      position: relative;
      @include sp {
        display: block;
        height: auto;
        padding: 4vw 0 0 7vw;
        width: 100%;
      }
    }
    .indent{
      font-size: 32/1366*100vw;
      text-indent: -1.1em;
      line-height: 1.3;
      @include sp{
        @include vw(44);
      }
    }
  }
  
  &__num{
    font-family: "Barlow";
    display: inline-block;
    font-size: 48/1366*100vw;
    color: #2CA6E0;
    vertical-align: sub;
    height: 1vw;
    @include sp{
      @include vw(62);
    }
  }
}