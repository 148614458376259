@charset "utf-8";
.p-welfare {
  &__inner {
    position: relative;
  }
  
  &__lead {
    position: relative;
    background: #FFF;
    padding: 110/1366*100vw 0;
    z-index: 1;
    @include sp {
      padding: 160/750*100vw 0;
    }
  }
  &__leadInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__leadTtl {
    color: #0F2C66;
    font-size: 28/1366*100vw;
    font-weight: 700;
    line-height: 1.8;
    @include sp {
      @include vw(34);
    }
  }
  &__leadTxt {
    color: #0F2C66;
    font-size: 20/1366*100vw;
    line-height: 1.8;
    margin-top: 25/1366*100vw;
    @include sp {
      @include vw(34);
      margin-top: 40/750*100vw;
    }
  }
  
  &__support {
    padding: 110/1366*100vw 0;
    background: #E7F0F8;
    @include sp {
      padding: 160/750*100vw 0;
    }
  }
  &__supportInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__supportBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 35/1366*100vw;
    @include sp {
      display: block;
      margin-top: 40/750*100vw;
    }
    &:after {
      display: block;
      content: '';
      width: 307/1366*100vw;
      @include sp {
        width: 100%;
      }
    }
  }
  &__supportBlockInner {
    background: #FFF;
    @include pc {
      width: 307/1366*100vw;
      padding: 35/1366*100vw;
      &:nth-of-type(n+4) {
        margin-top: 25/1366*100vw;
      }
    }
    @include sp {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 45/750*100vw;
      &:nth-of-type(n+2) {
        margin-top: 10/750*100vw;
      }
    }
  }
  &__supportBlockImg {
    width: 98/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 178/750*100vw;
      margin: auto;
    }
  }
  &__supportBlockTtl {
    color: $main;
    font-size: 18/1366*100vw;
    font-weight: 700;
    text-align: center;
    margin-top: 30/1366*100vw;
    @include sp {
      width: 355/750*100vw;
      @include vw(34);
      text-align: left;
      margin-top: 0;
      margin-left: 10/750*100vw;
    }
  }
  &__supportBlockTxt {
    font-size: 14/1366*100vw;
    line-height: 1.8;
    margin-top: 15/1366*100vw;
    @include sp {
      @include vw(24);
      margin-top: 30/750*100vw;
    }
  }
  
  
  &__institution {
    padding: 110/1366*100vw 0;
    background: #FFF;
    @include sp {
      padding: 160/750*100vw 0;
    }
  }
  &__institutionInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__institutionBlock {
    margin-top: 35/1366*100vw;
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
  &__institutionBlockInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include pc {
      &:nth-of-type(n+2) {
        margin-top: 60/1366*100vw;
      }
    }
    @include sp {
      display: block;
      &:nth-of-type(n+2) {
        margin-top: 80/750*100vw;
      }
    }
    &.fdr {
      flex-direction: row-reverse;
    }
  }
  &__institutionBlockImg {
    width: 480/1366*100vw;
    @include sp {
      width: 100%;
    }
  }
  &__institutionBlockArea {
    width: 440/1366*100vw;
    @include sp {
      width: 100%;
    }
  }
  &__institutionBlockTtl {
    color: $main;
    font-size: 20/1366*100vw;
    font-weight: 700;
    line-height: 1.8;
    @include sp {
      @include vw(32);
      margin-top: 40/750*100vw;
    }
  }
  &__institutionBlockTxt {
    font-size: 15/1366*100vw;
    line-height: 1.8;
    margin-top: 20/1366*100vw;
    text-align: justify;
    @include sp {
      @include vw(30);
      margin-top: 30/750*100vw;
    }
  }
}