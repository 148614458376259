@charset "utf-8";

@mixin sp {
  @media screen and (max-width: $breakPoint*1px) {
    @content;
  }
}

@mixin pc {
  @media print,
  screen and (min-width: $breakPoint+1px) {
    @content;
  }
}

@mixin rp {
  @media screen and (min-width: $breakPoint*1px) and (max-width: 1200px) {
    @content;
  }
}

@mixin vw($size) {
  font-size: ( $size /2) *1px;
  font-size: ( $size /$breakPoint *100) *1vw;
}

@mixin fs($size) {
  font-size: $size+px;
  font-size: ($size / $base_fs) * 1rem;
}

@mixin smoothScroll {
  -webkit-overflow-scrolling: touch;
}

//インデント
@mixin indent {
    text-indent: -1em;
    padding-left: 1em;
}
