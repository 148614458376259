@charset "utf-8";
.l-footer {
  background: #0F2C66;
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1254/1366*100vw;
    margin: 0 auto;
    padding: 80/1366*100vw 0 70/1366*100vw;
    @include sp {
      flex-wrap: wrap;
      width: 551/750*100vw;
      padding: 135/750*100vw 0 95/750*100vw;
    }
  }
  &__logo {
    min-width: 180px;
    width: 293/1366*100vw;
    @include sp {
      min-width: auto;
      width: 551/750*100vw;
    }
  }
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 400px;
    width: 570/1366*100vw;
    margin: 0 auto;
    @include sp {
      min-width: auto;
      width: 200/750*100vw;
      margin-top: 80/750*100vw;
    }
    &--sp{
      @include sp {
        min-width: auto;
        display: flex;
        width: 510/750*100vw;
        margin-top: 80/750*100vw;
      }
    }
  }
  &__listFlex{
    @include sp{
      display: flex;
    }
  }
  &__listBlock {
    &--01 {
      width: 250/1366*100vw;
      @include sp {
        display: none;
      }
    }
    &--02 {
      width: 200/1366*100vw;
      @include sp {
        display: none;
      }
    }
    &--03 {
      width: 150/1366*100vw;
      @include sp {
        display: none;
      }
    }
  }
  &__listBlockItem {
    margin-top: 5/1366*100vw;
    @include sp {
      margin-top: 20/750*100vw;
    }
    &.mt0 {
      margin-top: 0;
    }
    a {
      color: #B5BED0;
      font-size: 12/1366*100vw;
      line-height: 1.5;
      display: inline-block;
      @include sp {
        @include vw(24);
      }
      &.ttl {
        color: #FFF;
      }
    }
  }
  &__img {
    min-width: 60px;
    width: 88/1366*100vw;
    @include sp {
      min-width: auto;
      width: 107/750*100vw;
      margin-top: 80/750*100vw;
      margin-left: auto;
    }
  }
  &__copyright {
    width: 100%;
    padding: 10/1366*100vw 0;
    border-top: 1px solid rgba(255,255,255,0.15);
    @include sp {
      padding: 20/750*100vw 0;
    }
  }
  &__copyrightInner {
    width: 1254/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
  &__copyrightTxt {
    color: rgba(255,255,255,0.40);
    font-size: 12px;
    text-align: right;
    @include sp {
      @include vw(24);
      text-align: center;
    }
  }
}

.page_top{
  width: 60/1366*100vw;
  right: 50/1366*100vw;
  z-index: 99;
  @include sp{
    width: 80/750*100vw;
    right: 50/750*100vw;
  }
}
