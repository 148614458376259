@charset "utf-8";
.p-food {
    &__inner {
        position: relative;
        padding: 0 0 0;
        background: #E7F0F8;
        @include sp {
        padding: 0 0 200/750*100vw;
        }
    }
    &__wrap {
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp {
        width: 638/750*100vw;
        }
        &.mt {
        margin-top: 110/1366*100vw;
        @include sp {
            margin-top: 320/750*100vw;
        }
        }
    }

    &__lead {
        position: relative;
        background: #FFF;
        padding: 110/1366*100vw 0;
        z-index: 1;
        @include sp {
            padding: 130/750*100vw 0 160/750*100vw;
        }
    }
    &__leadInner {
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp {
        width: 638/750*100vw;
        }
    }
    &__leadTtl {
      font-size: 28/1366*100vw;
      font-weight: 700;
      color: #0F2C66;
      @include sp{
        @include vw(40);
        line-height: 1.8;
      }
    }
    &__leadText {
      font-size: 20/1366*100vw;
      color: #0F2C66;
      margin-top: 23/1366*100vw;
      line-height: 1.8;
      @include sp{
        @include vw(34);
        line-height: 1.8;
      }
    }  
    &__bldg {
      background: #E7F0F8;
    }
    &__bldgInner {
      width: 960/1366*100vw;
      margin: 0 auto;
      padding: 130/1366*100vw 0 110/1366*100vw;
      @include sp {
        width: 638/750*100vw;
        padding: 135/750*100vw 0;
      }
    }
    &__bldgList{
      margin-top: 65/1366*100vw;
      @include sp{
        margin-top: 40/750*100vw;
      }
    }
    &__bldgTtl {
      color: $main;
      font-size: 24/1366*100vw;
      font-weight: 700;
      line-height: 1.8;
      @include sp {
        @include vw(36);
      }
      span{
        display: inline-block;
        float: right;
        font-size: 15/1366*100vw;
        padding-top: 8/1366*100vw;
        @include sp {
          @include vw(28);
          float: left;
          padding-top: 24/750*100vw;
        }
      }
    }
    &__bldgText {
      color: $main;
      font-size: 16/1366*100vw;
      line-height: 1.8;
      margin-top: 30/1366*100vw;
      @include sp {
        @include vw(30);
        margin-top: 25/750*100vw;
      }
    }
    &__bldgBlock {
      margin-top: 25/1366*100vw;
      @include sp {
        display: block;
        margin-top: 70/750*100vw;
      }
      &:nth-of-type(n+2) {
        margin-top: 10/1366*100vw;
        @include sp {
          margin-top: 10/750*100vw;
        }
      }
    }

    &__bldgTable{
      border: solid 1px #E2E2E2;
      width: 960/1366*100vw;
      margin: 25/1366*100vw auto 0;
      @include sp{
        width: 638/750*100vw;
        margin: 20vw auto 0;
      }
    }
    &__bldgItemTtl{
      background: #0F2C66;
      color: #fff;
      padding: 12/1366*100vw 0;
      width: 320/1366*100vw;
      border: solid 1px #E2E2E2;
      font-size: 17/1366*100vw;
      @include sp{
        @include vw(27);
        width: auto;
        padding: 15/750*100vw 0;
      }
      &--01{
        @include sp{
          width: 165/750*100vw;
        }
      }
      &--02{
        @include sp{
          width: 295/750*100vw;
        }
      }
      &--03{
        @include sp{
          width: 175/750*100vw;
        }
      }
    }
    &__bldgItem{
      background: #fff;
      border: solid 1px #E2E2E2;
      height: 60/1366*100vw;
      vertical-align: middle;
      padding: 0 30/1366*100vw;
      font-size: 15/1366*100vw;
      @include sp{
        @include vw(22);
        height: 130/750*100vw;
        padding: 0 10/750*100vw;
      }
      &--height{
        height: 125/1366*100vw;
        @include sp{
          height: 200/750*100vw;
        }
      }
    }
    
    
    &__produce {
      padding: 110/1366*100vw 0 115/1366*100vw;
      background: #FFF;
      @include sp {
        padding: 160/750*100vw 0;
      }
    }
    &__produceInner {
      width: 960/1366*100vw;
      margin: 0 auto;
      @include sp {
        width: 638/750*100vw;
      }
    }
    &__produceTtl {
      color: $main;
      font-size: 24/1366*100vw;
      font-weight: 700;
      @include sp {
        @include vw(36);
      }
    }
    &__produceTxt {
      color: $main;
      font-size: 16/1366*100vw;
      line-height: 1.8;
      margin-top: 30/1366*100vw;
      @include sp {
        @include vw(30);
        margin-top: 25/750*100vw;
      }
    }
    
    &__produceTask{
      color: $main;
      font-size: 24/1366*100vw;
      font-weight: 700;
      text-align: center;
      margin-top: 55/1366*100vw;
      @include sp{
        @include vw(36);
      }
      span{
        display: block;
        font-size: 20/1366*100vw;
        font-weight: 700;
        margin-top: 15/1366*100vw;
        @include sp{
          @include vw(30);
          margin-top: 56/750*100vw;
        }
      }
    }
    &__produceList{
      display: flex;
      justify-content: space-between;
      margin-top: 40/1366*100vw;
      @include sp{
        display: block;
        margin-top: 25/750*100vw;
      }
    }
    
    &__produceItem{
      width: 305/1366*100vw;
      background: #E7F0F8;
      padding:  50/1366*100vw 0;
      @include sp{
        width: 638/750*100vw;
        margin: 15/750*100vw auto 0;
        padding: 50/750*100vw 0;
      }
      &:nth-child(1){
        @include sp{
          margin: 50/750*100vw auto 0;
        }
      }
    }
    &__produceArea{
      @include sp{
        display: flex;
        width: 550/750*100vw;
        margin: 30/750*100vw auto 0;
      }
    }
    
    &__produceImg{
      width: 145/1366*100vw;
      margin: 0 auto;
      @include sp{
        width: 162/750*100vw;
        margin: 0;
      }
    }
    &__produceItemeTtl{
      font-size: 20/1366*100vw;
      color: #0F2C66;
      font-weight: 700;
      text-align: center;
      margin-top: 15/1366*100vw;
      @include sp{
        @include vw(34);
        text-align: center;
      }
    }
    &__produceItemeText{
      font-size: 15/1366*100vw;
      color: #333333;
      text-align: center;
      margin-top: 15/1366*100vw;
      @include sp{
        @include vw(24);
        text-align: left;
        margin: 15/750*100vw 0 0 30/750*100vw;
      }
    }
    
    &__produceArrow{
      width: 153/1366*100vw;
      margin: 40/1366*100vw auto 0;
      @include sp{
        width: 125/750*100vw;
        margin: 55/750*100vw auto 0;
      }
    }
    
    &__produceResult{
      font-size: 20/1366*100vw;
      color: #0F2C66;
      font-weight: 700;
      text-align: center;
      margin-top: 40/1366*100vw;
      @include sp{
        @include vw(30);
        margin-top: 60/750*100vw;
      }
    }
    
    &__produceBlock{
        display: flex;
        margin-top: 40/1366*100vw;
        @include sp{
            display: block;
            margin-top: 50/750*100vw;
        }
    }
    
    &__produceBlockArea{
        @include pc{
            width: 50%;
        }
    }
    &__produceBlockTtl{
        font-size: 20/1366*100vw;
        color: #fff;
        font-weight: 700;
        background: #0F2C66;
        text-align: center;
        padding: 13/1366*100vw 0;
        @include sp{
            @include vw(34);
            padding: 23/750*100vw 0;
    }
    &.lightBlue{
        background: #2CA6E0;
      }
    }
    
    &__produceBlockText{
        font-size: 15/1366*100vw;
        background: #E7F0F8;
        padding: 30/1366*100vw 20/1366*100vw;
        @include sp{
            @include vw(24);
            padding: 55/750*100vw 30/750*100vw;
            line-height: 1.8;
        }
        span {
          font-size: 15/1366*100vw;
          text-indent: -1.3em;
          padding-left: 1.3em;
          display: block;
          @include sp {
            @include vw(24);
          }
        }
        &.lightBlue{
            background: #A8DBF5;
        }
    }
    
    &__merit {
        background: #E7F0F8;
    }
    &__meritInner {
        width: 960/1366*100vw;
        margin: 0 auto;
        padding: 130/1366*100vw 0 110/1366*100vw;
        @include sp {
            width: 638/750*100vw;
            padding: 160/750*100vw 0;
        }
    }
    &__meritTtl {
        color: $main;
        font-size: 24/1366*100vw;
        font-weight: 700;
        line-height: 1.8;
        @include sp {
            @include vw(32);
        }
    }
    &__meritTxt {
        color: $main;
        font-size: 16/1366*100vw;
        line-height: 1.8;
        margin-top: 30/1366*100vw;
        @include sp {
            @include vw(30);
            margin-top: 25/750*100vw;
        }
    }
    &__meritBlock {
        display: flex;
        margin-top: 60/1366*100vw;
        @include sp {
            display: block;
            margin-top: 70/750*100vw;
        }
        &:nth-of-type(n+2) {
            margin-top: 10/1366*100vw;
            @include sp {
            margin-top: 10/750*100vw;
            }
        }
    }
    &__meritBlockTtl {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 160/1366*100vw;
        color: #FFF;
        font-size: 15/1366*100vw;
        font-weight: 700;
        text-align: center;
        background: $main;
        @include sp {
            width: 100%;
            height: 175/750*100vw;
            @include vw(24);
        }
        span {
            font-size: 20/1366*100vw;
            margin-top: 10/1366*100vw;
            @include sp {
            @include vw(34);
            margin-top: 10/750*100vw;
            }
        }
    }
    &__meritBlockInner {
        display: flex;
        justify-content: space-between;
        width: 806/1366*100vw;
        background: #FFF;
        padding: 45/1366*100vw;
        @include sp {
            display: block;
            width: 100%;
            padding: 50/750*100vw 40/750*100vw;
        }
    }
    &__meritBlockArea {
        position: relative;
        width: 210/1366*100vw;
        @include sp {
            width: 100%;
        }
        &:nth-of-type(n+2) {
            @include sp {
            margin-top: 30/750*100vw;
            padding-top: 30/750*100vw;
            }
            &:before {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            left: -22/1366*100vw;;
            width: 1px;
            height: 100%;
            background: #CFD3DA;
            @include sp {
                top: 0;
                left: auto;
                width: 100%;
                height: 1px;
            }
            }
        }
    }
    &__meritBlockAreaTtl {
        color: $main;
        font-size: 18/1366*100vw;
        font-weight: 700;
        @include sp {
            @include vw(30);
        }
    }
    &__meritBlockAreaTxt {
        font-size: 15/1366*100vw;
        line-height: 1.8;
        margin-top: 10/1366*100vw;
        @include sp {
            @include vw(28);
            margin-top: 10/750*100vw;
        }
    }
    
    &__flow {
        padding: 110/1366*100vw 0 0;
        background: #FFF;
        @include sp {
            padding: 160/750*100vw 0;
        }
    }
    &__flowInner {
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp {
            width: 638/750*100vw;
        }
    }
    &__flowTtl {
        color: $main;
        font-size: 24/1366*100vw;
        font-weight: 700;
        @include sp {
            @include vw(36);
        }
    }
    &__flowTxt {
        color: $main;
        font-size: 16/1366*100vw;
        line-height: 1.8;
        margin-top: 30/1366*100vw;
        @include sp {
            @include vw(30);
            margin-top: 25/750*100vw;
        }
    }
    &__flowBlock {
        position: relative;
        display: flex;
        @include sp {
            display: block;
        }
        &:first-of-type {
            margin-top: 60/1366*100vw;
            @include sp {
            margin-top: 70/750*100vw;
            }
        }
        &:nth-of-type(n+2) {
            margin-top: 40/1366*100vw;
            @include sp {
            margin-top: 80/750*100vw;
            }
            &:before {
            position: absolute;
            display: block;
            content: '';
            background: url(/assets/img/service/food/arrow_01.svg);
            background-size: contain;
            background-repeat: no-repeat;
            @include pc {
                top: -25/1366*100vw;
                left: 100/1366*100vw;
                width: 41/1366*100vw;
                height: 21/1366*100vw;
            }
            @include sp {
                top: -50/750*100vw;
                left: 0;
                right: 0;
                width: 98/750*100vw;
                height: 51/750*100vw;
                margin: 0 auto;
            }
            }
        }
    }
    &__flowBlockTtl {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240/1366*100vw;
        color: #FFF;
        font-size: 18/1366*100vw;
        font-weight: 700;
        background: #0F2C66;
        @include sp {
            width: 100%;
            height: 100/750*100vw;
            @include vw(34);
        }
        &.blue {
            background: #2CA6E0;
        }
    }
    &__flowBlockArea {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 720/1366*100vw;
        height: 100/1366*100vw;
        padding: 0 25/1366*100vw;
        background: #E7F0F8;
        @include sp {
            width: 100%;
            height: auto;
            padding: 40/750*100vw;
        }
        &.blue {
            background: #A8DBF5;
        }
    }
    &__flowBlockAreaTxt {
        font-size: 15/1366*100vw;
        line-height: 1.8;
        @include sp {
            @include vw(28);
        }
    }
    &__flowAchieve {
        display: flex;
        margin-top: 110/1366*100vw;
        transition: all 0.3s;
        @include sp {
            display: block;
            margin-top: 160/750*100vw;
        }
        @include pc {
            &:hover {
            .p-food__flowAchieveLink {
                span:before {
                right: 0;
                }
            }
            }
        }
    }
    &__flowAchieveInner {
        width: 480/1366*100vw;
        padding: 65/1366*100vw 60/1366*100vw;
        background: #E7F0F8;
        @include sp {
            width: 100%;
            padding: 90/750*100vw 45/750*100vw;
        }
    }
    &__flowAchieveTtl {
        color: $main;
        font-size: 24/1366*100vw;
        font-weight: 700;
        @include sp {
            @include vw(36);
        }
    }
    &__flowAchieveTxt {
        color: $main;
        font-size: 16/1366*100vw;
        line-height: 1.8;
        margin-top: 20/1366*100vw;
        @include sp {
            @include vw(30);
            margin-top: 25/750*100vw;
        }
    }
    &__flowAchieveLink {
        margin-top: 30/1366*100vw;
        @include sp {
            margin-top: 50/750*100vw;
    }
    span {
        position: relative;
        color: #2CA6E0;
        font-size: 18/1366*100vw;
        font-weight: 700;
        padding-right: 60/1366*100vw;
        transition: all 0.3s;
        @include sp {
            @include vw(30);
            padding-right: 80/750*100vw;
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            right: 6/1366*100vw;
            width: 26/1366*100vw;
            height: 7/1366*100vw;
            background: url(/assets/img/top/arrow.svg);
            background-size: cover;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            transition: all 0.3s;
            @include sp {
                right: 0;
                width: 40/750*100vw;
                height: 11/750*100vw;
            }
        }
        &:after {
            position: absolute;
            display: block;
            content: '';
            bottom: -10/1366*100vw;
            left: 0;
            width: 100%;
            height: 1px;
            background: #2CA6E0;
            @include sp {
                bottom: -20/750*100vw;
            }
        }
    }
    }
    &__flowAchieveImg {
    width: 480/1366*100vw;
        @include sp {
            width: 100%;
        }
    }
}