@charset "utf-8";

.p-advantage {
    &__inner {
        position: relative;
        padding: 0 0 160/1366*100vw;
        @include sp {
          padding: 0 0 200/750*100vw;
        }
    }
    
    &__str{
        position: relative;
        &::before{
            content: '';
            display: block;
            z-index: -1;
            width: 523/1366*100vw;
            height: 459/1366*100vw;
            position: absolute;
            top:0;
            right: 0;
            background: #0f2c66;
            @include sp{
                width: 532/750*100vw;
                height: 530/750*100vw;
                top: 85vw;
            }
        }
        &--03{
            @include sp{
                &::before{
                    width: 532/750*100vw;
                    height: 510/750*100vw;
                    top: 61.5vw;
                }
            }
        }
        &--04{
            @include sp{
                &::before{
                    width: 532/750*100vw;
                    height: 510/750*100vw;
                    top: 75vw !important;
                }
            }
        }
        &.reverse{
            position: relative;
            &::before{
                content: '';
                display: block;
                z-index: -1;
                width: 523/1366*100vw;
                height: 459/1366*100vw;
                position: absolute;
                top:0;
                left: 0;
                background: #0f2c66;
                @include sp{
                    width: 532/750*100vw;
                    height: 530/750*100vw;
                    top: 60vw;
                }
            }
        }
    }
    &__strInner{
        width: 1080/1366*100vw;
        margin: 135/1366*100vw auto 0;
        display: flex;
        justify-content: space-between;
        @include sp{
            display: block;
            width: 638/750*100vw;
            margin: 240/750*100vw auto 0;
        }
        &.reverse{
            flex-direction: row-reverse;
        }
    }
    &__strTtl{
        font-size: 32/1366*100vw;
        color:$main;
        height: 7.018vw;
        @include sp{
            @include vw(40);
            height: auto;
        }
    }
    &__strBlock{
        position: relative;
        width: 480/1366*100vw;
        margin-top: 125/1366*100vw;
        @include sp{
            width: 638/750*100vw;
            margin-top: 0;
        }
        &.mt{
            margin-top: 40/1366*100vw;
        }
    }
    &__strText{
        width: 520/1366*100vw;
        margin-top: 55/1366*100vw;
        font-size: 17/1366*100vw;
        color: $main;
        line-height: 1.8;
        @include sp{
            width: auto;
            @include vw(32);
            margin-top: 55/750*100vw;
        }
        &--03{
            width: 510/1366*100vw;
            @include sp{
                width: auto;
            }
        }
    }
    &__strNum{
        position: absolute;
        top: -3vw;
        right: 0;
        z-index: -1;
        font-family: $font-family-barlow;
        font-size: 175/1366*100vw;
        font-weight: bold;
        color: #E5F4FA;
        line-height: 0.8;
        letter-spacing: 0.1rem;
        text-align: center;
        @include sp{
            @include vw(230);
            top: -13vw;
        }
        span{
            font-size: 30/1366*100vw;
            display: block;
            letter-spacing: 1px;
            @include sp{
                @include vw(40);
            }
        }
        
    }
    &__strImg{
        width: 560/1366*100vw;
        @include sp{
            width: 585/750*100vw;
            margin-top: 80/750*100vw;
        }

        &--01{
            padding-left: 60px;
            @include sp{
                padding-left: 0;
            }
        }
        &--02{
            padding-right: 60px;
            @include sp{
                width: auto;
                padding-right: 0;
                padding-left: 50/750*100vw;
            }
        }
        &--03{
            padding-left: 60px;
            @include sp{
                padding-left: 0;
            }
        }
        &--04{
            padding-right: 60px;
            @include sp{
                width: auto;
                padding-right: 0;
                padding-left: 50/750*100vw;
            }
        }
    }
    &__group{
        background: #E7F0F8;
        padding: 30/1366*100vw 0;
        margin-top: 80/1366*100vw;
        @include sp{
            margin-top: 80/750*100vw;
            padding: 112/750*100vw 0;
        }
    }
    
    &__groupItem{
        width: 960/1366*100vw;
        margin: 13/1366*100vw auto;
        background: #fff;
        padding: 25/1366*100vw 30/1366*100vw;
        @include sp{
            width: 638/750*100vw;
            padding: 44/750*100vw;
            margin: 25/750*100vw auto;
        }
    }
    &__groupTtl{
        color: $main;
        font-size: 17/1366*100vw;
        font-weight: bold;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 10/1366*100vw;
        @include sp{
            @include vw(30);
            padding-bottom: 23/750*100vw;
        }
        span{
            font-size: 15/1366*100vw;
            float: right;
            color: #2B2B2B;
            @include sp{
                @include vw(24);
            }
        }
    }
    
    &__groupText{
        padding-top: 15/1366*100vw;
        font-size: 15/1366*100vw;
        @include sp{
            @include vw(28);
            padding-top: 23/750*100vw;
            line-height: 1.8;
        }
    }
    &__groupArea{
        display: flex;
        justify-content: space-between;
        margin: 24/1366*100vw auto 10/1366*100vw;
        @include sp{
            display: block;
            margin: 25/750*100vw auto 20/750*100vw;
        }
    }
    &__groupList{
        width: 438/1366*100vw;
        border: solid 1px #E2E2E2;
        @include sp{
            width: 550/750*100vw;
            margin-top: 25/750*100vw;
        }
    }
    &__groupItemTtl{
        font-size: 17/1366*100vw;
        font-weight: bold;
        color: #fff;
        background: $main;
        border: solid 1px #E2E2E2;
        text-align: center;
        padding: 12/1366*100vw 0;
        @include sp{
            padding: 15/750*100vw 0;
            @include vw(28);
        }
    }
    
    &__groupItemText{
        width: 140/1366*100vw;
        font-size: 15/1366*100vw;
        border: solid 1px #E2E2E2;
        color: #2B2B2B;
        text-align: center;
        padding: 12/1366*100vw 0;
        vertical-align: middle;
        @include sp{
            width: 110/750*100vw;
            @include vw(26);
            padding: 15/750*100vw 0;
        }
        &.license{
            width: 300/1366*100vw;
            text-align: left;
            padding: 12/1366*100vw 35/1366*100vw;
            @include sp{
                width: 440/750*100vw;
                @include vw(26);
                padding: 15/750*100vw 25/750*100vw;
            }
        }
    }
    
    &__achieve{
        display: grid;
        margin-top: 15/1366*100vw;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5/1366*100vw 0;
        @include sp{
            grid-template-columns: none;
        }
    }
    &__groupItemArea{
        height: 94/1366*100vw;
        line-height: 1.8;
        @include sp{
            height: auto;
        }
    }
    &__achieveItem{
        font-size: 15/1366*100vw;
        @include sp{
            @include vw(26);
        }
        &:nth-child(5){
            grid-row: span 1; grid-column: span 2;
            @include sp{
                grid-row: span none; grid-column: span none;
            }
        }
    }
}