@charset "utf-8";
.m-h2ttl {
  color: #0F2C66;
  font-size: 28/1366*100vw;
  font-weight: 700;
  letter-spacing: 0.1em;
  @include sp {
    @include vw(36);
  }
  &--en {
    display: block;
    color: #2CA6E0;
    font-size: 20/1366*100vw;
    font-weight: 700;
    font-family: $font-family-barlow;
    @include sp {
      @include vw(28);
    }
  }
  &--ja {
    display: block;
    color: $main;
    font-size: 32/1366*100vw;
    font-weight: 700;
    margin-top: 5/1366*100vw;
    @include sp {
      @include vw(40);
      margin-top: 5/750*100vw;
    }
  }
  &.mt {
    margin-top: 110/1366*100vw;
    @include sp {
      margin-top: 110/750*100vw;
    }
  }
}