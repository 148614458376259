@charset "utf-8";
.p-customer {
    &__wrap {
        width: 960/1366*100vw;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        @include sp {
        width: 638/750*100vw;
        }
    }
    &__block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 960/1366*100vw;
        margin: 30/1366*100vw auto 0;
        @include sp {
            width: 638/750*100vw;
            margin: 45/1366*100vw auto 0;
        }
    }
    &__blockInner {
        width: 304/1366*100vw;
        background: #FFF;
        transition: all 0.3s;
        @include pc {
        &:nth-of-type(n+4) {
            margin-top: 50/1366*100vw;
        }
        &:hover {
            .p-achievements__blockArea {
            &:before {
                right: 19/1366*100vw;
            }
            }
        }
        }
        @include sp {
            width: 638/750*100vw;
            margin-top: 45/750*100vw;
            &:first-child{
                margin-top: 0;
            }
        }
    }
    &__blockArea {
        position: relative;
        height: 120/1366*100vw;
        padding: 25/1366*100vw;
        @include sp {
        min-height: 200/750*100vw;
        height: auto;
        padding: 20/750*100vw 45/750*100vw 60/750*100vw 45/750*100vw;
        }
        &:before {
        position: absolute;
        display: block;
        content: '';
        bottom: 25/1366*100vw;
        right: 25/1366*100vw;
        width: 35/1366*100vw;
        height: 10/1366*100vw;
        background: url(/assets/img/top/arrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.3s;
        @include sp {
            bottom: 65/750*100vw;
            right: 50/750*100vw;
            width: 40/750*100vw;
            height: 11/750*100vw;
        }
        }
    }
    &__blockTtl {
        color: #0F2C66;
        font-size: 15/1366*100vw;
        font-weight: 700;
        margin-top: 10/1366*100vw;
        @include sp {
        @include vw(36);
        margin-top: 15/750*100vw;
        }
    }
    
    
    //下層共通
    &__kv {
        position: relative;
        margin-top: 60/1366*100vw;
        padding: 80/1366*100vw 0 105/1366*100vw;
        background: $main;
        @include sp {
        margin-top: 110/750*100vw;
        padding: 70/750*100vw 0 110/750*100vw;
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            left: 0;
            width: 845/1366*100vw;
            height: 100/1366*100vw;
            background: #FFF;
            @include sp {
                width: 220/750*100vw;
                height: 70/750*100vw;
            }
        }
        &:after {
            position: absolute;
            display: block;
            content: '';
            bottom: 0;
            left: 0;
            width: 1000/1366*100vw;
            height: 445/1366*100vw;
            background: #FFF;
            @include sp {
                top: 70/750*100vw;
                width: 540/750*100vw;
                height: 544/750*100vw;
            }
        }
        &--noImg{
            @include sp{
                padding: 70/750*100vw 0 470/750*100vw;
            }
            &::after{
                @include sp {
                    top: 0;
                    width: 650/750*100vw;
                    height: 544/750*100vw;
                }
            }
        }
    }
    &__kvInner {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 960/1366*100vw;
        margin: 0 auto;
        z-index: 1;
        @include sp {
            display: block;
            width: 638/750*100vw;
        }
        
        &--noImg{
            width: 900/1366*100vw;
            margin: 0;
            @include sp{
                width: 750/750*100vw;
            }
        }
    }
    &__kvImg {
        position: relative;
        width: 500/1366*100vw;
        z-index: 1;
        @include sp {
            width: 100%;
        }
    }
    &__kvBlock {
        width: 410/1366*100vw;
        @include sp {
            position: absolute;
            bottom: -55vw;
            left: 0;
            right: 0;
            width: 638/750*100vw;
            margin: 0 auto;
        }
        &--noImg{
            width: 710/1366*100vw;
            // display: flex;
            // justify-content: space-between;
            @include sp {
                bottom: -50vw;
                display: block;
                width: 600/750*100vw;
                margin: 0 auto;
            }
        }
        &--itochu {
        @include sp {
            bottom: -92vw;
        }
        }
        &--meitetsu {
        @include sp {
            bottom: -70vw;
        }
        }
        &--nissan_osaka {
            width: 710/1366*100vw;
            display: flex;
            justify-content: space-between;
            @include sp {
                bottom: -48vw;
                display: block;
                width: 550/750*100vw;
                margin: 0 auto;
            }
        }
        &--nissin {
        @include sp {
            bottom: -77vw;
        }
        }
        &--yanase {
        @include sp {
            bottom: -70vw;
        }
        }
    }
    &__kvTtl {
        color: $main;
        font-size: 27/1366*100vw;
        font-weight: 700;
        margin-top: 20/1366*100vw;
        line-height: 1.4;
        @include sp {
            @include vw(36);
            margin-top: 30/750*100vw;
        }
    }
    &__kvTxt {
        font-size: 16/1366*100vw;
        line-height: 1.8;
        margin-top: 35/1366*100vw;
        font-weight: bold;
        @include sp {
            @include vw(30);
            margin-top: 60/750*100vw;
        }
        &.mt{
            margin-top: 15/1366*100vw;
            @include sp{
                margin-top: 30/750*100vw;
            }
        }
        &.meitetsu{
            width: 390/1366*100vw;
            @include sp{
                width: auto;
            }
        }
    }
    &__inner{
        &--isuzu{
            @include sp{
                margin-top: 500/750*100vw;
            }
        }
        &--nissan_osaka{
            @include sp{
                margin-top: 450/750*100vw;
            }
        }
        &--nissin{
            @include sp{
                margin-top: 611/750*100vw;
            }
        }
        &--yanase{
            @include sp{
                margin-top: 580/750*100vw;
            }
        }
    }
    &__imgList{
        width: 960/1366*100vw;
        margin: 100/1366*100vw auto 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include sp{
            width: 638/750*100vw;
            display: block;
            margin: 150/750*100vw auto 0;
        }
        &::after{
            content: '';
            display: inline-block;
            width: 310/1366*100vw;
        }
    }
    &__imgItem{
        width: 310/1366*100vw;
        margin-top: 13/1366*100vw;
        @include sp{
            width: 638/750*100vw;
            margin-top: 20/750*100vw;
        }
    }
    &__ach{
        background: #E7F0F8;
        padding: 95/1366*100vw 0;
        @include sp{
            padding: 135/750*100vw 0;
        }
    }
    &__achInner{
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
    }
    &__achText{
        font-size: 24/1366*100vw;
        color: $main;
        font-weight: bold;
        text-align: center;
        @include sp{
            @include vw(36);
        }
    }
    &__achWork{
        display: grid;
        grid-template-columns: 160/1366*100vw 800/1366*100vw;
        height: auto;
        @include sp{
            display: block;
            grid-template-columns: none;
        }
    }
    &__achWorkTtl{
        font-size: 20/1366*100vw;
        color: #fff;
        font-weight: bold;
        background: $main;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            @include vw(34);
            padding: 30/750*100vw 0;
        }
    }
    &__achWork{
        margin-top: 30/1366*100vw;
        @include sp{
            margin-top: 45/750*100vw;
        }
    }
    &__achWorkDetailTtl{
        font-size: 17/1366*100vw;
        font-weight: bold;
        color: $main;
        @include sp{
            @include vw(32);
        }
        &.mt{
            padding-top: 25/1366*100vw;
            @include sp{
                padding-top: 63/750*100vw;;
            }
        }
    }
    &__achWorkDetail{
        background: #fff;
        padding: 54/1366*100vw 50/1366*100vw 56/1366*100vw;
        @include sp{
            padding: 65/750*100vw 45/750*100vw 80/750*100vw;
        }
    }
    &__achachWorkList{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5/1366*100vw;
        padding-top: 10/1366*100vw;
        @include sp{
            grid-template-columns: none;
            gap: 10/750*100vw;
            padding-top: 40/750*100vw;
        }
    }
    &__achachWorkItem{
        text-indent: -1.2em;
        padding-left: 1em;
        font-size: 17/1366*100vw;
        @include sp{
            @include vw(30);
        }
    }
    &__baseMainTtl{
        font-size: 24/1366*100vw;
        font-weight: bold;
        color: $main;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 15/1366*100vw;
        @include sp{
            @include vw(34);
            padding-bottom: 20/750*100vw;
        }
    }
    &__baseInner{
        width: 960/1366*100vw;
        margin: 112/1366*100vw auto 0;
        @include sp{
            width: 638/750*100vw;
            margin: 135/750*100vw auto 0;
        }
    }
    &__baseList{
        margin: 40/1366*100vw auto 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25/1366*100vw;
        @include sp{
            grid-template-columns: none;
            margin: 25/750*100vw auto 0;
        }
    }
    &__baseItem{
        width: 468/1366*100vw;
        @include sp{
            width: 638/750*100vw;
        }
        &.meitetsu{
            &:nth-child(1){
                .p-customer__baseBlock.bottom-meitetsu{
                    grid-auto-rows: 110/1366*100vw;
                    @include sp{
                        grid-auto-rows: auto;
                    }
                }
            }
            &:nth-child(3){
                .p-customer__baseBlock.bottom-meitetsu{
                    grid-auto-rows: 200/1366*100vw;
                    @include sp{
                        grid-auto-rows: auto;
                    }
                }
            }
            &:nth-child(6){
                .p-customer__baseBlock.bottom-meitetsu{
                    grid-auto-rows:minmax(80/1366*100vw, auto);
                    @include sp{
                        grid-auto-rows: auto;
                    }
                }
            }
            &:nth-child(10){
                .p-customer__baseBlock.bottom-meitetsu{
                    grid-auto-rows:80/1366*100vw;
                    @include sp{
                        grid-auto-rows: auto;
                    }
                }
            }
            &:nth-child(11){
                .p-customer__baseBlock.bottom-meitetsu{
                    grid-auto-rows: 170/1366*100vw;
                    @include sp{
                        grid-auto-rows: auto;
                    }
                }
            }
        }
    }
    &__baseImg{
        position: relative;
    }
    &__baseTag{
        width: 96/1366*100vw;
        display: block;
        background: #2CA6E0;
        color: #fff;
        font-size: 12/1366*100vw;
        font-weight: bold;
        text-align: center;
        padding: 5/1366*100vw 0;
        position: absolute;
        left: 12/1366*100vw;
        top:  12/1366*100vw;
        @include sp{
            width: 180/750*100vw;
            @include vw(22);
            padding: 10/750*100vw 0;
            left: 15/750*100vw;
            top:  15/750*100vw;
        }
    }
    &__baseArea{
        background: #ECF3F9;
    }
    &__baseTtl{
        font-size: 15/1366*100vw;
        font-weight: bold;
        color: #333333;
        @include sp{
            @include vw(24);
        }
    }
    &__baseBlock{
        display: grid;
        grid-template-columns: 80/1366*100vw 350/1366*100vw;
        padding: 20/1366*100vw 23/1366*100vw;
        border-bottom: solid 1px #fff;
        @include sp{
            grid-template-columns: 130/750*100vw 453/750*100vw;
            padding: 30/750*100vw 35/750*100vw;
        }
        &.top-isuzu{
            grid-auto-rows: 40/1366*100vw;
            @include sp{
                grid-auto-rows : 80/750*100vw;
            }
        }
        &.bottom-isuzu{
            grid-auto-rows: 220/1366*100vw;
            // height: 200/1366*100vw;
            @include sp{
                grid-auto-rows: 370/750*100vw;
            }
            .p-customer__baseText {
                &.grid{
                    grid-template-columns: 120/1366*100vw 1fr;
                    @include sp{
                        grid-template-columns: 24vw 1fr;
                    }
                }
            }
        }
    }
    &__baseText{
        font-size: 15/1366*100vw;
        line-height: 1.6;
        @include sp{
            @include vw(23);
        }
        &.grid{
            display: grid;
            grid-template-columns: 130/1366*100vw 1fr;
            gap: 5/1366*100vw;
            // align-items: center;
            @include sp{
                gap: 20/750*100vw;
                grid-template-columns: 24vw 1fr;
            }
            span {
                line-height: 1.6;
                @include sp{
                    @include vw(23);
                }
            }
        }
        &.grid2{
            display: grid;
            grid-template-columns: 180/1366*100vw 1fr;
            gap: 5/1366*100vw;
            // align-items: center;
            @include sp{
                gap: 20/750*100vw;
                grid-template-columns: 140/750*100vw 300/750*100vw;
            }
            span {
                line-height: 1.6;
            }
        }
        span{
            font-size: 15/1366*100vw;
            @include sp{
                @include vw(23);
            }
        }
        .left{
            font-size: 15/1366*100vw;
            display: inline-block;
            text-align: left;
            @include sp{
                @include vw(23);
            }
        }
    }
    
    &__detail{
        width: 960/1366*100vw;
        margin: 112/1366*100vw auto 0;
        @include sp{
            width: 638/750*100vw;
            margin: 135/750*100vw auto 0;
        }
        &.mt0{
            margin: 0 auto;
        }
    }
    &__detailTtl{
        font-size: 24/1366*100vw;
        font-weight: bold;
        color: $main;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 15/1366*100vw;
        @include sp{
            @include vw(34);
            padding-bottom: 20/750*100vw;
        }
    }
    
    &__detailItem{
        width: 468/1366*100vw;
        @include sp{
            width: 638/750*100vw;
        }
    }
    &__detailSubTtl{
        font-size: 17/1366*100vw;
        font-weight: bold;
        color: $main;
        @include sp{
            @include vw(30);
        }
    }
    &__detailList{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40/1366*100vw 25/1366*100vw;
        margin-top: 40/1366*100vw;
        @include sp{
            grid-template-columns: none;
            gap: 50/750*100vw 0;
            margin-top: 30/750*100vw;
        }
    }
    &__detailFactory{
        margin-top: 15/1366*100vw;
        @include sp{
            margin-top: 20/750*100vw;
        }
        ul {
            li {
                font-size: 15/1366*100vw;
                padding: 10/1366*100vw 20/1366*100vw;
                color: #333333;
                border-top: solid 1px #E2E2E2;
                border-left: solid 1px #E2E2E2;
                border-right: solid 1px #E2E2E2;
                @include sp{
                    @include vw(28);
                    padding: 20/750*100vw 20/750*100vw;
                }
                &:last-child{
                    border-bottom: solid 1px #E2E2E2;
                }
                &:has(p) {
                    display: flex;
                    @include sp{
                        display: block;
                    }
                }
                p {
                    &:first-child {
                        width: calc(100% - 15em);
                        font-size: 15/1366*100vw;
                        @include sp{
                            @include vw(28);
                            width: auto;
                        }
                    }
                    &:nth-child(2) {
                        width: calc(100% - 8em);
                        font-size: 15/1366*100vw;
                        @include sp{
                            @include vw(28);
                            width: auto;
                        }
                    }
                }
            }
        }
    }
    &__detailFactoryName{
        width: calc(100% - 1em) !important;
        font-size: 15/1366*100vw;
        @include sp{
            @include vw(28);
            width: auto !important;
        }
    }
    &__detailFactoryTtl{
        font-size: 17/1366*100vw;
        font-weight: bold;
        color: #2B2B2B;
        text-align: center;
        background: #ECF3F9;
        padding: 10/1366*100vw 0;
        @include sp{
            @include vw(28);
            padding: 15/750*100vw 0;
        }
    }
    
    
    &__link{
        padding: 110/1366*100vw 0 160/1366*100vw 0;
        text-align: center;
        @include sp{
            padding: 135/750*100vw 0 230/750*100vw 0;
        }
        a{
            font-size: 19/1366*100vw;
            font-weight: bold;
            color: #2CA6E0;
            display: inline-block;
            text-align: center;
            position: relative;
            padding: 0 20/1366*100vw 0 50/1366*100vw;
            @include sp{
                @include vw(30);
                padding: 0 30/750*100vw 0 80/750*100vw;
            }
            &::before{
                content: '';
                display: inline-block;
                background: url('../img/customer/arrow.svg')no-repeat top left;
                background-size: contain;
                width: 25/1366*100vw;
                height: 7/1366*100vw;
                position: absolute;
                left: 6/1366*100vw;
                transform: translateY(-50%);
                top: 50%;
                transition: all 0.3s;
                @include sp{
                    width: 40/750*100vw;
                    height: 10/750*100vw;
                    left: 10/750*100vw;
                }
            }
            &::after{
                content: '';
                display: inline-block;
                border-bottom: solid 1px #2CA6E0;
                width: 188/1366*100vw;
                position: absolute;
                bottom: -15/1366*100vw;
                left: 50%;
                transform: translate(-50%, 0);
                @include sp{
                    width: 270/750*100vw;
                    bottom: -15/750*100vw;
                }
            }
            @include pc{
                &:hover{
                    &:before {
                        left: 0px;
                      }
                }
            }
        }
    }
    &__factory{
        padding: 115/1366*100vw 0 0; 
    }
    &__factoryInner{
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
    }    
    &__factoryItem{
        display: grid;
        grid-template-columns: 240/1366*100vw 720/1366*100vw;
        @include sp{
            grid-template-columns: none;
        }
        &--column{
            grid-template-columns: 123/1366*100vw 117/1366*100vw;
            @include sp{
                grid-template-columns: none;
            }
        }
    }
    
    &__factoryChildList{
        display: contents;
        @include sp{
            display: grid;
            grid-template-columns: 200/638*100vw 342/638*100vw;
        }
    }
    &__factoryTtl{
        background: #ECF3F9;
        font-size:  15/1366*100vw;
        vertical-align: middle;
        padding: 15/1366*100vw 0;
        border: solid 1px #E2E2E2;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            @include vw(28);
            display: block;
            padding: 20/750*100vw 0;
        }
        &--top{
            align-items: baseline;
        }
        &--bond{
            grid-row:1/4;
            display: flex;
            align-items: center;
            justify-content: center;
            @include sp{
                grid-row:1/1;
            }
        }
    }
    &__factoryText{
        // width: 720/1366*100vw;
        border: solid 1px #E2E2E2;
        font-size:  15/1366*100vw;
        padding: 15/1366*100vw 30/1366*100vw;
        line-height: 2.0;
        @include sp{
            @include vw(26);
            display: block;
            padding: 20/750*100vw 30/750*100vw;
        }
        &--column{
            grid-column: 3/3;
            @include sp{
                grid-column: 2/3;
            }
        }
        .indent{
            font-size:  15/1366*100vw;
            text-indent: -1em;
            padding-left: 1em;
            @include sp{
                @include vw(26);
            }
        }
        .indent02{
            font-size:  15/1366*100vw;
            text-indent: -3em;
            padding-left: 5em;
            display: inline-block;
            @include sp{
                @include vw(26);
            }
        }
        .indent03{
            font-size:  15/1366*100vw;
            text-indent: -0em;
            padding-left: 3em;
            display: inline-block;
            @include sp{
                @include vw(26);
            }
        }
    }
}