@charset "utf-8";

.p-sitemap{
  &__inner{
    position: relative;
    padding: 0 0 160/1366*100vw;
    width: 860/1366*100vw;
    margin: 0 auto;
    @include sp{
      width: 680/750*100vw;
    }
  }
  &__linkBtn{
    font-size:17/1366*100vw;
    color: #0F2C66;
    font-weight: 700;
    border: solid 2px #E2E2E2;
    width: 175/1366*100vw;
    padding: 10/1366*100vw 0;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-top: -3vw;
    position: relative;
    @include sp{
      width: 314/750*100vw;
      padding: 25/750*100vw;
      @include vw(34);
      margin-top: 60/750*100vw;
    }
    &::after{
      content: '';
      display: inline-block;
      box-sizing: border-box;
      width: 12/1366*100vw;
      height: 10/1366*100vw;
      border: 5/1366*100vw solid transparent;
      border-left: 10/1366*100vw solid #2ca6e0;
      position: absolute;
      top: 50%;
      right: 10%;
      transform: rotate(90deg);
      @include sp{
        width: 15/750*100vw;
        height: 14/750*100vw;
        border: 8/750*100vw solid transparent;
        border-left: 20/750*100vw solid #2ca6e0;
        right: 7%;
        top: 47%;
      }
    }
  }
  &__map{
    margin-top: 50/1366*100vw;
    padding-bottom: 50/1366*100vw;
    border-bottom: solid 1px #E2E2E2;
    @include sp{
      margin-top: 65/750*100vw;
      padding-bottom: 80/750*100vw;
    }
  }
  &__mapBlock{
    margin-bottom: 50/1366*100vw;
    @include sp{
      margin-bottom: 80/750*100vw;
    }
  }
  &__mapTtl{
    background: #E7F0F8;
    padding: 13/1366*100vw 25/1366*100vw;
    @include sp{
      padding: 25/750*100vw 32/750*100vw;
    }
    a{
      font-size: 17/1366*100vw;
      font-weight: 700;
      color: #0F2C66;
      text-decoration: underline;
      @include sp{
        @include vw(34);
      }
    }
  }
  &__mapList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include sp{
      grid-template-columns: 1.2fr 1fr;
    }
    //サービスソリューション
    &.service{
      display: flex;
      justify-content: space-between;
      @include sp{
        display: grid;
        grid-template-columns: 1.2fr 1fr;
      }
      .p-sitemap__mapItem{
        &:first-child{
          padding: 0 0 0 25/1366*100vw;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(3){
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(4){
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(5){
          padding: 0 0 0 25/1366*100vw;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
      }
    }
    //主要取引先
    &.customer{
      grid-template-columns: 1fr 1fr 1fr;
      @include sp{
        grid-template-columns: 1fr;
      }
      .p-sitemap__mapItem{
        @include sp{
          padding: 0 0 0 32/750*100vw;
        }
        &:first-child{
          padding: 0 0 0 25/1366*100vw;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(4){
          padding: 0 0 0 25/1366*100vw;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(5){
          padding: 0;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
      }
    }
    //企業情報
    &.company{
      .p-sitemap__mapItem{
        &:first-child{
          padding: 0 0 0 25/1366*100vw;
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(3){
          @include sp{
            padding: 0 0 0 32/750*100vw;
          }
        }
        &:nth-child(4){
          @include sp{
            padding: 0;
          }
        }
      }
    }
  }
  &__mapItem{
    margin-top:25/1366*100vw;
    &.column{
      @include sp{
        grid-column: 1 / 4;
      }
    }
    @include sp{
      margin-top: 45/750*100vw;
    }
    a{
      font-size: 15/1366*100vw;
      text-decoration: underline;
      @include sp{
        @include vw(28);
      }
    }
  }
  
  &__map02{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 20/1366*100vw;
    @include sp{
      grid-template-columns: 1fr;
      gap: 50/750*100vw;
    }
  }
  
  &__link{
    padding: 60/1366*100vw 0 0 0;
    @include sp{
      padding: 110/750*100vw 0 230/750*100vw 0;
    }
  }
  
  &__linkList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 15/1366*100vw;
    margin-top: 30/1366*100vw;
    @include sp{
      @include vw(30);
      grid-template-columns: 1fr;
      gap: 50/750*100vw;
      margin-top: 50/750*100vw;
    }
  }
  &__linkItem{
    a{
      font-size: 15/1366*100vw;
      text-decoration: underline;
      @include sp{
        @include vw(30);
      }
    }
  }
}