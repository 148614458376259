@charset "utf-8";
.p-profile {
  &__inner {
    position: relative;
    margin-top: 110/1366*100vw;
    background: #E7F0F8;
  }
  &__list {
    width: 960/1366*100vw;
    margin: 0 auto;
    padding: 110/1366*100vw 0 160/1366*100vw;
    @include sp {
      width: 638/750*100vw;
      padding: 130/750*100vw 0;
    }
  }
  &__listItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__listTtl {
    width: 140/1366*100vw;
    min-height: 60/1366*100vw;
    color: $main;
    font-size: 18/1366*100vw;
    font-weight: 700;
    line-height: 1.8;
    text-align: right;
    padding-right: 20/1366*100vw;
    @include sp {
      width: 148/750*100vw;
      min-height: 10vw;
      @include vw(28);
      padding: 3vw 20/750*100vw 3vw 0;
    }
  }
  &__listTxt {
    width: 820/1366*100vw;
    min-height: 60/1366*100vw;
    font-size: 16/1366*100vw;
    line-height: 1.8;
    padding-left: 20/1366*100vw;
    padding-bottom: 40/1366*100vw;
    border-left: 1px solid #0F2C66;
    @include sp {
      width: 490/750*100vw;
      min-height: 10vw;
      @include vw(28);
      padding-left: 20/750*100vw;
      padding: 3vw 0 3vw 20/750*100vw;
    }
    span {
      font-size: 16/1366*100vw;
      font-weight: 700;
      @include sp {
        @include vw(28);
      }
    }
    &.pb {
      @include sp {
        padding-bottom: 10vw;
      }
    }
    &.flex {
      display: flex;
      min-height: auto;
      padding-bottom: 0;
      padding-left: 0;
      @include sp{
        display: block;
        padding-left: 20/750*100vw;
      }
      &:nth-of-type(n+2) {
        padding-top: 20/1366*100vw;
      }
    }
  }
  &__listTxtTtl{
    font-size: 17/1366*100vw;
    margin-top: 15/1366*100vw;
    font-weight: 700;
    display: block;
    &.top{
      margin-top: 0;
    }
  }
  &__listTxtPosition {
    width: 190/1366*100vw;
    font-size: 16/1366*100vw;
    padding-left: 20/1366*100vw;
    text-align: left;
    @include sp {
      width: 50%;
      @include vw(28);
      text-align: left;
      padding-left: 0;
    }
  }
  &__listTxtName {
    width: 100%;
    font-size: 16/1366*100vw;
    @include sp {
      width: 100%;
      @include vw(28);
    }
  }
  
  &__dev{
    background: #FFF;
  }
  
  &__devInner{
    width: 960/1366*100vw;
    margin: 0 auto;
    padding: 110/1366*100vw 0 80/1366*100vw;
    @include sp{
      width: 638/750*100vw;
      margin: 0 auto;
      padding: 157/750*100vw 0 150/750*100vw;
    }
  }
  &__devList{
    padding-left: 35/1366*100vw;
    padding-top: 35/1366*100vw;
    @include sp{
      padding-left: 0;
      padding-top: 50/750*100vw;
    }
  }
  &__devItem{
    display: flex;
  }
  
  &__devTtl{
    color: #0F2C66;
    font-size:17/1366*100vw;
    font-weight: 700;
    width: 130/1366*100vw;
    @include sp{
      @include vw(28);
      width: 150/750*100vw;
      text-align: right;
      padding-right: 5vw;
      padding-bottom: 50/750*100vw;
    }
  }
  &__border{
    border-left: solid 1px #A7DCF5;
    position: relative;
    &.top{
      margin-top: 8/1366*100vw;
      &::before{
        content: '';
        display: inline-block;
        border: solid 2px #a7dcf5;
        border-radius: 80px;
        width: 13px;
        height: 13px;
        background: #E7F0F8;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);
        @include sp{
          width: 20/750*100vw;
          height: 20/750*100vw;
        }
      }
    }
    &.last{
      border-left: solid 1px #A7DCF5;
      height: 20px;
      @include sp{
        height: 3vw;
      }
      &::before{
        content: '';
        display: inline-block;
        border: solid 2px #a7dcf5;
        border-radius: 80px;
        width: 13px;
        height: 13px;
        background: #E7F0F8;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, 0%);
        @include sp{
          width: 20/750*100vw;
          height: 20/750*100vw;
        }
      }
    }
    &::before{
      content: '';
      display: inline-block;
      border: solid 2px #a7dcf5;
      border-radius: 80px;
      width: 13px;
      height: 13px;
      background: #E7F0F8;
      position: absolute;
      top: 6%;
      left: 50%;
      transform: translate(-50%, 0%);
      @include sp{
        width: 20/750*100vw;
        height: 20/750*100vw;
        top: 3%;
      }
    }
  }
  &__devTxt{
    font-size:17/1366*100vw;
    padding-left: 35/1366*100vw;
    padding-bottom: 50/1366*100vw;
    line-height: 1.6;
    @include sp{
      @include vw(28);
      width: 480/750*100vw;
      padding-left: 30/750*100vw;
      padding-bottom: 60/750*100vw;
    }
    span{
      font-size:17/1366*100vw;
      padding-bottom: 5/1366*100vw;
      color: #333333;
      font-weight: 700;
      display: block;
      @include sp{
        @include vw(28);
      }
    }
  }
  
  &__access {
    padding: 110/1366*100vw 0;
    background: #E7F0F8;
    @include sp {
      padding: 160/750*100vw 0;
    }
  }
  &__accessInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__accessBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 10/750*100vw ;
    @include sp {
      display: block;
      background: none;
      padding: 50/750*100vw 0;
      border-bottom: solid 1px #CFD3DA;
    }
    &:nth-of-type(1){
      margin-top: 20/750*100vw;
    }
    &:nth-of-type(3){
      @include sp{
        border-bottom: none;
      }
    }
  }
  &__accessBlockMap {
    width: 480/1366*100vw;
    height: 340/1366*100vw;
    @include sp {
      width: 100%;
      height: 452/750*100vw;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__accessBlockArea {
    width: 435/1366*100vw;
    @include sp {
      width: 100%;
      margin-top: 40/750*100vw;
    }
  }
  &__accessBlockAreaTtl {
    color: $main;
    font-size: 20/1366*100vw;
    font-weight: 700;
    @include sp {
      @include vw(30);
    }
  }
  &__accessBlockAreaTxt {
    font-size: 15/1366*100vw;
    line-height: 1.8;
    margin-top: 20/1366*100vw;
    @include sp {
      @include vw(28);
      margin-top: 20/750*100vw;
    }
  }
}