@charset "utf-8";

.p-nationwide {
    &__inner {
        position: relative;
        padding: 0 0 160/1366*100vw;
        background: #E7F0F8;
        @include sp {
          padding: 0 0 200/750*100vw;
        }
    }
    &__lead {
        position: relative;
        background: #FFF;
        padding: 110/1366*100vw 0;
        z-index: 1;
        @include sp {
          padding: 120/750*100vw 0;
        }
    }
    &__leadInner {
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp {
            width: 638/750*100vw;
        }
    }
    &__leadTtl {
        color: #0F2C66;
        font-size: 28/1366*100vw;
        font-weight: 700;
        line-height: 1.8;
        @include sp {
          @include vw(34);
        }
    }
    &__leadTxt {
        color: #0F2C66;
        font-size: 20/1366*100vw;
        line-height: 1.8;
        margin-top: 25/1366*100vw;
        @include sp {
          @include vw(34);
          margin-top: 40/750*100vw;
        }
    }
    &__bg{
        background: #fff;
        padding: 95/1366*100vw 0;
    }
    &__link{
        width: 960/1366*100vw;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 0 auto;
        @include sp{
            width: 637/750*100vw;
            grid-template-columns: 1fr;
            gap: 15/1366*100vw;
        }
    }
    &__linkItem{
        a{
            position: relative;
            background: #E7F0F8;
            display: flex;
            align-items: center;
            padding: 30/1366*100vw;
            width: 315/1366*100vw;
            height: 64/1366*100vw;
            @include sp{
                width: 638/750*100vw;
                height: 112/750*100vw;
                padding: 40/750*100vw 55/750*100vw;
                justify-content: center;
            }
            &::before{
                content: '';
                display: inline-block;
                background: url('../img/service/management/arrow.svg')no-repeat center center;
                background-size: contain;
                width: 10/1366*100vw;
                height: 9/1366*100vw;
                position: absolute;
                top: 45%;
                right: 25/1366*100vw;
                @include sp{
                    width: 17/750*100vw;
                    height: 19/750*100vw;
                    right: 100/1366*100vw;
                }
            }
        }
        p{
            text-align: center;
            color: #0F2C66;
            font-size: 17/1366*100vw;
            font-weight: 700;
            @include sp{
                @include vw(34);
                justify-content: center;
            }
        }
        span{
            font-size: 17/1366*100vw;
            font-weight: 700;
            display: inline-block;
            padding-right: 25/1366*100vw;
            color: #0F2C66;
            @include sp{
                @include vw(34);
                position: absolute;
                left: 50/750*100vw
            }
        }
    }
    &__area{
        background: #FFFFFF;
        width: 960/1366*100vw;
        margin: 65/1366*100vw auto;
        padding: 60/1366*100vw 0;
        @include sp{
            width: 638/750*100vw;
            margin: 135/750*100vw auto;
            padding: 95/750*100vw 0;
        }
    }
    &__list{
        width: 730/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 550/750*100vw;
        }
    }
    &__item{
        font-size: 20/1366*100vw;
        color: #0F2C66;
        padding: 0 0 0 40/1366*100vw;
        margin-bottom: 20/1366*100vw;
        font-weight: bold;
        position: relative;
        @include sp{
            @include vw(32);
            padding:0 0 0 60/750*100vw;
            margin-bottom: 50/750*100vw;
        }
        &::before{
            content: '';
            display: inline-block;
            background: url('../img/service/nationwide/check.svg')no-repeat center center;
            background-size: contain;
            width: 25/1366*100vw;
            height: 25/1366*100vw;
            position: absolute;
            top: 10%;
            left: 0;
            @include sp{
                width: 38/750*100vw;
                height: 35/750*100vw;
            }
        }
    }
    &__listText{
        text-align: center;
        color: #0F2C66;
        font-weight: bold;
        width: 760/1366*100vw;
        margin: 40/1366*100vw auto 0;
        background:linear-gradient(transparent 70%,#A8DAF2 0%);
        span{
            font-size: 24/1366*100vw;
            @include sp{
                background:linear-gradient(transparent 70%,#A8DAF2 0%);
            }
            @include sp{
                @include vw(32);
            }
        }
        @include sp{
            background: none;
            width: 490/750*100vw;
            line-height: 1.8;
        }
    }
    &__meritBox{
        width: 960/1366*100vw;
        background: #fff;
        padding: 60/1366*100vw 0;
        @include sp{
            width: 638/750*100vw;
            padding: 65/750*100vw 0;
        }
    }
    
    &__meritBoxImg{
        width: 730/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 550/750*100vw;
        }
    }
    
    &__wapper{
        margin-top: 115/1366*100vw;
    }
    &__ttl{
        font-size: 24/1366*100vw;
        color: #0F2C66;
        font-weight: 700;
        @include sp{
            @include vw(36);
        }
        &--width{
            @include sp{
                width: 638/750*100vw;
                margin: 0 auto;
            }
        }
    }
    &__meritTtl{
        font-size: 20/1366*100vw;
        color: #fff;
        font-weight: 700;
        background: #6B89C7;
        padding: 10/1366*100vw 0;
        text-align: center;
        margin-top: 25/1366*100vw;
        @include sp{
            @include vw(36);
            padding: 20/750*100vw 0;
            margin-top: 50/750*100vw;
        }
        &.mt{
            margin-top: 40/1366*100vw;
        }
    }
    &__block{
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
        &--width{
            @include sp{
                width: 100%;
            }
        }
        &.mt{
            margin: 80/1366*100vw auto 0;
            @include sp{
                margin: 125/750*100vw auto 0;
            }
        }
    }
    &__meritArea{
        display: grid;
        grid-template-columns: 170/1366*100vw 790/1366*100vw;
        height: 135/1366*100vw;
        margin-top: 5/1366*100vw;
        @include sp{
            grid-template-columns: 1fr;
            height: auto;
            margin-top: 15/750*100vw;
        }
    }
    &__meritSubTtl{
        background: #0F2C66;
        font-size: 17/1366*100vw;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        @include sp{
            @include vw(32);
            padding: 25/750*100vw 0;
        }
    }
    &__meritText{
        background: #fff;
        display: flex;
        align-items: center;
        @include sp{
            
        }
        p{
            font-size: 15/1366*100vw;
            padding: 0 32/1366*100vw;
            @include sp{
                @include vw(28);
                padding: 50/750*100vw 45/750*100vw;
            }
            span{
                font-size: 17/1366*100vw;
                font-weight: 700;
                color: #2B2B2B;
                display: block;
                margin-bottom: 10/1366*100vw;
                @include sp{
                    @include vw(28);
                    margin-bottom: 25/750*100vw;
                }
            }
        }
    }
    &__subTtl{
        font-size: 17/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 10/1366*100vw;
        @include sp{
            @include vw(34);
            padding-bottom: 15/750*100vw;
        }
    }
    
    &__about{
        background: #FFFFFF;
        width: 960/1366*100vw;
        padding: 90/1366*100vw 0 110/1366*100vw 0;
        margin-top: 25/1366*100vw;
        @include sp{
            width: 100%;
            padding: 80/750*100vw 0;
            margin-top: 45/750*100vw;
        }
    }
    &__aboutInner{
        width: 800/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
    }
    &__aboutTop{
        font-size: 15/1366*100vw;
        font-weight: 700;
        margin-bottom:40/1366*100vw;
        color: #0F2C66;
        @include sp{
            @include vw(30);
            margin-bottom: 60/750*100vw;
        }
    }
    &__aboutWorkTtl{
        background: #0F2C66;
        color: #FFFFFF;
        font-size: 20/1366*100vw;
        font-weight: 700;
        text-align: center;
        padding: 10/1366*100vw 0;
        @include sp{
            @include vw(34);
            font-weight: bold;
            padding: 30/750*100vw 0;
        }
    }
    &__aboutWorkBg{
        background: #F2F3F7;
        padding: 25/1366*100vw 0;
        @include sp{
            padding: 35/750*100vw 0;;
        }
    }
    &__aboutWorkList{
        width: 616/1366*100vw;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        @include sp{
            width: 590/750*100vw;
        }
    }
    &__aboutWorkItem{
        border-radius: 50%;
        width: 174/1366*100vw;
        height: 174/1366*100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            width: 200/750*100vw;
            height: 200/750*100vw;
        }
        &:nth-child(1){
            background: #8AA1D0;
            @include sp{
                margin-top: 120/750*100vw;
            }
        }
        &:nth-child(2){
            background: #7091D5;
        }
        &:nth-child(3){
            background: #5887CC;
            @include sp{
                margin-top: 120/750*100vw;
            }
        }
    }
    &__aboutWorkArea{
        text-align: center;
    }
    &__aboutWorkImg{
        margin: 0 auto;
        &--01{
            width: 38/1366*100vw;
            @include sp{
                width: 60/750*100vw;
                height: auto;
            }
        }
        &--02{
            width: 54/1366*100vw;
            @include sp{
                width: 80/750*100vw;
            }
        }
        &--03{
            width: 45/1366*100vw;
            @include sp{
                width: 55/750*100vw;
            }
        }
    }
    &__aboutWorkText{
        font-size: 18/1366*100vw;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 1.3;
        margin-top: 10/1366*100vw;
        @include sp{
            @include vw(25);
            margin-top: 10/750*100vw;
        }
    }
    &__aboutArrow{
        width: 65/1366*100vw;
        margin: 17/1366*100vw auto;
        @include sp{
            width: 92/750*100vw;
        }
    }
    &__aboutSummary{
        font-size: 24/1366*100vw;
        color: #fff;
        font-weight: 700;
        background: #2CA6E0;
        padding: 12/1366*100vw 0;
        border-radius: 50px;
        margin-top: 20/1366*100vw;
        text-align: center;
        @include sp{
            @include vw(32);
            padding: 30/750*100vw 0;
            margin-top: 20/750*100vw;
        }
    }
    
    &__aboutText{
        font-size: 15/1366*100vw;
        margin-top: 30/1366*100vw;
        @include sp{
            @include vw(30);
            margin-top: 35/750*100vw;
        }
    }
    
    &__aboutBlock{
        margin-top: 50/1366*100vw;
        @include sp{
            margin-top: 111/750*100vw;
        }
    }
    &__aboutTable{
        margin-top: 40/1366*100vw;
        @include sp{
            margin-top: 60/750*100vw;
        }
    }
    &__aboutTableTtl{
        font-size: 17/1366*100vw;
        color: #fff;
        font-weight: 700;
        text-align: center;
        background: #0F2C66;
        padding: 11/1366*100vw 0;
        @include sp{
            @include vw(28);
            padding: 20/750*100vw 0;
        }
        &.column02{
            display: grid;
            grid-template-columns: 320/1366*100vw 480/1366*100vw;
            padding: 0;
            @include sp{
                grid-template-columns: 250/750*100vw 390/750*100vw;
            }
            p {
                font-size: 17/1366*100vw;
                border-left: solid 1px #E2E2E2;
                padding: 11/1366*100vw 0;
                @include sp{
                    @include vw(28);
                    padding: 20/750*100vw 20/750*100vw;
                }
            }
        }
    }
    
    &__aboutTableList{
        display: grid;
        grid-template-columns: 320/1366*100vw 480/1366*100vw;
        @include sp{
            grid-template-columns: 250/750*100vw 390/750*100vw;
        }
    }
    
    &__aboutTableItem{
        border: solid 1px #E2E2E2;
        padding: 15/1366*100vw 30/1366*100vw;
        height: auto;
        display: grid;
        align-items: center;
        font-size: 15/1366*100vw;
        line-height: 1.6;
        @include sp{
            display: block;
            @include vw(26);
            padding: 20/750*100vw 20/750*100vw;
        }
        .indent{
            text-indent: -1.2em;
            padding-left: 1em;
            font-size: 15/1366*100vw;
            display: block;
            @include sp{
                @include vw(26);
                padding-left: 1em;
                text-indent: -1em;
                display: inline-block;
            }
        }
        .indent2{
            font-size: 15/1366*100vw;
            margin-left: -1.2em;
            @include sp{
                @include vw(26);
                margin-left: -1em;
            }
        }
    }
    &__aboutVoice{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 40/1366*100vw auto 100/1366*100vw;
        @include sp{
            display: block;
            margin: 45/750*100vw auto 110/750*100vw;
        }
        &.mb{
            margin: 40/1366*100vw auto 0;
        }
    }
    &__aboutVoiceItem{
        background: #E7F0F8;
        width: 256/1366*100vw;
        height:auto;
        display: flex;
        align-items: center;
        padding:6px 15/1366*100vw;
        margin-right: 15/1366*100vw;
        font-size: 15/1366*100vw;
        font-weight: bold;
        color: #0F2C66;
        position: relative;
        @include sp{
            @include vw(30);
            width: 638/750*100vw;
            height: 205/750*100vw;
            padding:40/750*100vw 45/750*100vw;
            margin: 70/750*100vw auto;
        }
        &:first-child{
            @include sp{
                margin: 0/750*100vw auto;
            }
        }
        &:nth-child(3){
            margin-right: 0;
        }
        &::after {
            content: "";
            position: absolute;
            // right: 0;
            left: 50%;
            width: 0;
            height: 0;
            // margin: auto;
            margin-left: -1vw;
            // bottom: -10px;
            bottom: -1vw;
            border-style: solid;
            border-color: #e7f0f8 transparent transparent  transparent;
            // border-width: 12px 11px 0 0;
            border-width: 1.2vw 1.2vw 0 0;
            @include sp {
                bottom: -4.8vw;
                border-color: #e7f0f8 transparent transparent  transparent;
                border-width: 5.3vw 4.5vw 0 0;
            }
        }
        // &::before{
        //     content: '';
        //     display: inline-block;
        //     background: url('../img/service/nationwide/voice_arrow.svg') no-repeat center center;
        //     width: 28/1366*100vw;
        //     height: 31/1366*100vw;
        //     position: absolute;
        //     bottom: -20%;
        //     left: 50%;
        //     transform: translate(-50%, 0);
        //     @include sp{
        //         background-size: contain;
        //         width: 45/750*100vw;
        //         height: 50/750*100vw;
        //     }
        // }
        &.block{
            display: block;
            padding: 25/1366*100vw 15/1366*100vw;
            @include sp{
                height: auto;
                padding: 55/750*100vw 40/750*100vw;
            }
            &::before{
                bottom: -12%;
            }
        }
        &.mt{
            margin-top: 50/1366*100vw;
        }
    }
    &__aboutVoiceIcon{
        padding: 0 0 15/1366*100vw 50/1366*100vw;
        border-bottom: solid 1px #C2CFDF;
        margin-bottom: 20/1366*100vw;
        position: relative;
        font-size: 14/1366*100vw;
        @include sp{
            @include vw(30);
            padding: 0 0 30/750*100vw 115/750*100vw;
        }
        &::before{
            content: '';
            display: inline-block;
            background: url('../img/service/nationwide/voice_icon.png') no-repeat center center;
            background-size: contain;
            width: 18%;
            height: 108%;
            position: absolute;
            left: 0;
            top: -30%;
            @include sp{
                width: 86/750*100vw;
                height: 86/750*100vw;
            }
        }
    }
    &__aboutFlex{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40/1366*100vw;
        @include sp{
            display: block;
            margin-top: 60/750*100vw;
        }
    }
    &__aboutImg{
        width: 392/1366*100vw;
        @include sp{
            width: 638/750*100vw;
            margin-top: 50/750*100vw;
        }
        &:nth-child(3){
            margin-top: 40/1366*100vw;
            @include sp{
                margin-top: 50/750*100vw;
            }
        }
        &.w100{
            width: 100vw;
            @include sp{
                width: 638/750*100vw;
            }
        }
    }
    &__aboutImgText{
        font-size: 15/1366*100vw;
        margin-top: 15/1366*100vw;
        text-align: center;
        @include sp{
            @include vw(26);
            margin-top: 32/750*100vw;
        }
    }
    
    &__concept{
        margin-top: 25/1366*100vw;
        @include sp{
            margin-top: 50/750*100vw;
        }
    }
    &__conceptItem{
        background: #FFFFFF;
        height: 121/1366*100vw;
        display: flex;
        align-items: center;
        padding: 0 45/1366*100vw;
        margin-top: 8/1366*100vw;
        @include sp{
            height: auto;
            padding: 55/750*100vw 45/750*100vw;
            margin-top: 15/750*100vw;
        }
    }
    &__conceptText{
        font-size: 20/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        @include sp{
            @include vw(32);
        }
    }
    
    &__flow{
        background: #FFFFFF;
        width: 960/1366*100vw;
        padding: 90/1366*100vw 0 110/1366*100vw 0;
        margin-top: 25/1366*100vw;
        @include sp{
            width: 100%;
            padding: 80/750*100vw 0;
            margin-top: 45/750*100vw;
        }
    }
    &__flowBlock{
        &.mt{
            margin-top: 65/1366*100vw;
        }
    }
    &__flowInner{
        width: 800/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
    }
    &__flowText{
        font-size: 15/1366*100vw;
        margin-top: 32/1366*100vw;
        color: #2B2B2B;
        @include sp{
            @include vw(30);
            margin-top: 32/750*100vw;
        }
    }
    &__flowImg{
        margin-top: 50/1366*100vw;
    }
}