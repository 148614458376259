@charset "utf-8";
.p-policy {
  &__inner {
    position: relative;
    padding: 0 0 160/1366*100vw;
    @include sp {
      padding: 0 0 200/750*100vw;
    }
  }
  
  &__box {
    position: relative;
    background: #FFF;
    padding: 110/1366*100vw 0 0;
    z-index: 1;
    @include sp {
      padding: 160/750*100vw 0;
    }
    &+.p-policy__box {
      padding: 60/1366*100vw 0 0;
    }
  }
  &__boxInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__ttl {
    color: #0F2C66;
    font-size: 28/1366*100vw;
    font-weight: 700;
    line-height: 1.8;
    @include sp {
      @include vw(34);
    }
  }
  &__txt {
    color: #000;
    font-size: 15/1366*100vw;
    line-height: 1.8;
    margin-top: 25/1366*100vw;
    @include sp {
      @include vw(34);
      margin-top: 40/750*100vw;
    }
    a {
      text-decoration: underline;
    }
  }
  
}