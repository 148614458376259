/**
 * initiallize
 * タグ自体のstyleを記述
 */

html {
  font-family: $font-family-basic;
  color: $color_txt;
  word-wrap: break-word;
  font-size: $base_fs * 1px;
  line-height: 1.5;
  letter-spacing: 0.05em;
}


body {
 position: relative;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

a {
  text-decoration: $link_deco;
  color: $color_lnk;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

img {
  width: 100%;
  vertical-align: middle;
}

input {
  padding: 0;
  border: none;
  background: none;
}

* {
  box-sizing: border-box;
  transition: font-size 0.1s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

input[type=text],
input[type=tel],
input[type=password],
input[type=email],
input[type=search],
input[type=url],
input[type=datetime],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime-local],
input[type=number],
select,
textarea {
  height: 42px;
  padding: 6px;
  font-family: $font-family-basic;
  background-color: #F7F7F7;
  border: 1px solid #EAEAEA;
  vertical-align: bottom;
  @include sp {
    height: 60/750*100vw;
    @include vw(24);
    padding: 10/750*100vw;;
  }
  &::-webkit-input-placeholder {
    color: $color_placeholder;
    @include sp {
      @include vw(20);
    }
  }
  &:-ms-input-placeholder {
    color: $color_placeholder;
    @include sp {
      @include vw(20);
    }
  }
  &::-moz-placeholder {
    color: $color_placeholder;
    @include sp {
      @include vw(20);
    }
  }
  &:focus {
    outline: 0;
    background-color: #eef4fb;
  }
}

input[type=radio],
input[type=checkbox] {
  margin: 0;
  vertical-align: -1px;
}
input[type=checkbox]:checked {
  color: #005c27;
  background: #005c27;
}

input[type="button"],
input[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 341px;
  height: 54px;
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  vertical-align: middle;
  margin: 0 auto;
  background-color: #747474;
  border: 1px solid #747474;
  cursor: pointer;
  font-family: $font-family-basic;
  transition: all 0.3s;
  -webkit-appearance: none;
  @include sp {
    width: 500/750*100vw;
    height: 90/750*100vw;
    @include vw(24);
  }
  &:focus {
    outline: 0;
  }
  @include pc {
    &:hover {
      color: #747474;
      background-color: #FFF;
    }
  }
}

textarea {
  resize: vertical;
  vertical-align: bottom;
}

blockquote {
  background-color: #EEEFFF;
  padding: 1em 1em 1em 3em;
  position: relative;
  border-left: 3px solid #666;
}

blockquote:before {
  content: "“";
  font-size: 600%;
  line-height: 1em;
  color: #999;
  position: absolute;
  left: 0;
  top: 0;
}

::selection {
  background: #d4dcd6;
  /* Safari */
}

::-moz-selection {
  background: #d4dcd6;
  /* Firefox */
}
@include pc {
  .w50 {
    width: 50px;
  }
  .w75 {
    width: 75px;
  }
  .w100 {
    width: 100px;
  }
  .w125 {
    width: 125px;
  }
  .w150 {
    width: 150px;
  }
  .w175 {
    width: 175px;
  }
  .w200 {
    width: 200px;
  }
  .w225 {
    width: 225px;
  }
  .w250 {
    width: 250px;
  }
  .w275 {
    width: 275px;
  }
  .w300 {
    width: 300px;
  }
  .w325 {
    width: 325px;
  }
  .w350 {
    width: 350px;
  }
  .w375 {
    width: 375px;
  }
  .w400 {
    width: 400px;
  }
  .w425 {
    width: 425px;
  }
  .w450 {
    width: 450px;
  }
  .w475 {
    width: 475px;
  }
  .w500 {
    width: 500px;
  }
  .w525 {
    width: 525px;
  }
  .w550 {
    width: 550px;
  }
  .w575 {
    width: 575px;
  }
  .w600 {
    width: 600px;
  }
  .w625 {
    width: 625px;
  }
  .w650 {
    width: 650px;
  }
  .w675 {
    width: 675px;
  }
  .w700 {
    width: 700px;
  }
  .w725 {
    width: 725px;
  }
  .w750 {
    width: 750px;
  }
  .w775 {
    width: 775px;
  }
  .w800 {
    width: 800px;
  }
  .w825 {
    width: 825px;
  }
  .w850 {
    width: 850px;
  }
  .w875 {
    width: 875px;
  }
  .w900 {
    width: 900px;
  }
  .w925 {
    width: 925px;
  }
  .w950 {
    width: 950px;
  }
  .w975 {
    width: 975px;
  }
  .w1000 {
    width: 1000px;
  }
}