@charset "utf-8";
.p-top {
  
  //kv
  &__kv {
    position: relative;
  }
  &__kvImg {
    width: 100%;
    margin-top: 60/1366*100vw;
    @include sp{
      margin-top: 110/750*100vw;
    }
  }
  &__kvTtl {
    position: absolute;
    top: 49%;
    left: 50%;
    right: 0;
    width: 990/1366*100vw;
    margin: 0 auto;
    transform: translate(-50%,-50%);
    @include sp {
      width: 650/750*100vw;
    }
  }
  
  &__inner{
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp{
      width: 638/750*100vw;
    }
  }
  
  &__area{
    display: flex;
    justify-content: space-between;
    @include sp{
      display: block;
    }
  }
  &__ttl{
    line-height: 1.3;
    &--en {
      display: block;
      color: #2CA6E0;
      font-size: 25/1366*100vw;
      font-weight: 700;
      font-family: $font-family-barlow;
      @include sp {
        @include vw(32);
      }
    }
    &--ja {
      display: block;
      color: #0F2C66;
      font-size: 44/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      @include sp {
        @include vw(56);
      }
    }
  }
  
  &__link {
    margin-top: 50/1366*100vw;
    @include sp {
      margin-top: 55/750*100vw;
      margin-bottom: 20/750*100vw;
    }
    a {
      position: relative;
      color: #2CA6E0;
      font-size: 18/1366*100vw;
      font-weight: 700;
      padding-right: 80/1366*100vw;
      transition: all 0.3s;
      @include sp {
        @include vw(33);
        padding-right: 80/750*100vw;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        right: 6/1366*100vw;
        width: 35/1366*100vw;
        height: 10/1366*100vw;
        background: url(/assets/img/top/arrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transition: all 0.3s;
        @include sp {
          right: 0;
          width: 38/750*100vw;
          height: 11/750*100vw;
          background-size: contain;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: -10/1366*100vw;
        left: 0;
        width: 100%;
        height: 1px;
        background: #2CA6E0;
        @include sp {
          bottom: -20/750*100vw;
        }
      }
      @include pc {
        &:hover {
          &:before {
            right: 0;
          }
        }
      }
    }
  }
  
  &__strength{
    background: #E7F0F8;
    padding: 135/1366*100vw 0 160/1366*100vw 0;
    @include sp{
      padding: 135/750*100vw 0 160/750*100vw 0;
    }
  }
    
  &__strengthText{
    font-size: 22/1366*100vw;
    font-weight: bold;
    color: $main;
    margin-top: 20/1366*100vw;
    @include sp{
      @include vw(34);
      margin-top: 60/750*100vw;
    }
  }
  
  &__strengthList{
    display: flex;
    flex-wrap: wrap;
    @include sp{
      display: block;
      margin-top: 80/750*100vw;
    }
  }
  
  &__strengthItem{
    width: 410/1366*100vw;
    height: 185/1366*100vw;
    display: grid;
    grid-template-columns: 30/1366*100vw 382/1366*100vw;
    margin: 35/1366*100vw 45/1366*100vw 0 0;
    box-shadow: 10px 5px 5px #DFE6EC;
    @include sp{
      width: 540/750*100vw;
      height: 380/750*100vw;
      grid-template-columns: 54/750*100vw 500/750*100vw;
      margin: 40/750*100vw 45/750*100vw 0 0;
    }
    &:nth-child(2){
      @include sp{
        margin-left: 90/750*100vw;
      }
    }
    &:nth-child(3){
      margin-top: 15/1366*100vw;
      margin-left: 75/1366*100vw;
      margin-right: 0;
      @include sp{
        margin-left: 0;
        margin-top: 40/750*100vw;
      }
    }
    &:nth-child(4){
      margin-left: 45/1366*100vw;
      margin-top: 15/1366*100vw;
      margin-right: 0;
      @include sp{
        margin-left: 90/750*100vw;
        margin-top: 40/750*100vw;
      }
    }
  }
  
  &__strengthTag{
    font-family: $font-family-barlow;
    font-size: 11/1366*100vw;
    font-weight: bold;
    background: #0F2C66;
    color: #6F80A3;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    padding: 15/1366*100vw 0 0 0;
    @include sp{
      @include vw(20);
      padding: 20/750*100vw 0 0 0;
    }
  }
  
  &__strengthBlock{
    background: #fff;
    padding: 0 30/1366*100vw;
    position: relative;
    display: flex;
    align-items: center;
    @include sp{
      padding: 0 40/750*100vw;
    }
  }
  
  &__strengthNum{
    font-family: $font-family-barlow;
    font-size: 100/1366*100vw;
    font-weight: bold;
    color: #E0F2FA;
    position: absolute;
    top: -10/1366*100vw;
    right: 35/1366*100vw;
    z-index: 1;
    @include sp{
      @include vw(135);
      top: -10/750*100vw;
      right: 45/750*100vw;
    }
  }
  
  &__strengthTtl{
    font-size: 23/1366*100vw;
    color: $main;
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    z-index: 3;
    @include sp{
      @include vw(38);
    }
  }
  
  
  &__strengthItemText{
    font-size: 16/1366*100vw;
    line-height: 1.4;
    font-weight: 700;
    color: #0f2c66;
    margin-top: 10/750*100vw;
    position: relative;
    z-index: 2;
    @include sp{
      @include vw(30);
      margin-top: 30/750*100vw;
    }
  }
  
  &__service{
    background: #fff;
    padding: 158/1366*100vw 0;
    position: relative;
    z-index: 5;
    @include sp{
      padding: 157/750*100vw 0 0;
    }
    &::after{
      content: '';
      display: inline-block;
      background: #E7F0F8;
      width: 100%;
      height: 910/1366*100vw;
      margin-top: -55vw;
      position: relative;
      z-index: -1;
      @include sp{
        height: 2375/750*100vw;
        margin-top: -294vw;
      }
    }
  }
  
  &__serviceItem{
    margin-top: 55/1366*100vw;
    @include sp{
      margin-top: 90/750*100vw;
    }
    &--side{
      display: grid;
      grid-template-columns: 320/1366*100vw 640/1366*100vw;
      margin-top: 10/1366*100vw;
      @include sp{
        grid-template-columns: none;
        margin-top: 15/750*100vw;
      }
      .p-top__serviceBlockInner{
        width: 640/1366*100vw;
        grid-template-columns: none;
        @include sp{
          grid-template-columns: none;
          width: 638/750*100vw;
          margin: 0 auto;
        }
        &::before{
          border-right: none;
          @include sp{
            border-bottom: none;
          }
        }
      }
      .p-top__serviceItemImg{
        @include sp{
          display: grid;
          grid-template-columns: 216/750*100vw 422/750*100vw;
        }
      }
      .p-top__serviceBlock{
        height: 204/1366*100vw;
        @include sp{
          height: 585/750*100vw;
        }
      }
      .p-top__serviceArea{
        width: 560/1366*100vw;
        margin: 0 auto;
        @include sp{
          width: 550/750*100vw;
          padding: 30/750*100vw 0;
        }
      }
      .p-top__serviceTtl{
        @include sp{
          padding-left: 60/750*100vw;
          height: 216/750*100vw;
          display: flex;
          align-items: center;
        }
      }
      .p-top__serviceText{
        min-height: 60/1366*100vw;
        height: auto;
        @include sp{
          min-height: none;
          height: auto;
          grid-column-start: 1;
        }
      }
    }
  }
  
  &__serviceBlock {
    background: #fff;
    height: 230/1366*100vw;
    @include sp{
      height: auto;
    }
  }
  
  &__serviceBlockInner{
    width: 900/1366*100vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      border-right:  solid 1px #E2E2E2;
      height: 160/1366*100vw;
      top: 30/1366*100vw;
      left: 50%;
      @include sp{
        height: auto;
        border-right: none;
        border-bottom: solid 1px #E2E2E2;
        width: 550/750*100vw;
        top: 48%;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
    @include sp{
      width: 638/750*100vw;
      grid-template-columns: none;
    }
  }
  
  &__serviceArea{
    width: 395/1366*100vw;
    margin: 0 auto;
    padding: 20/1366*100vw 0 0 0;
    @include sp{
      width: 550/750*100vw;
      padding: 50/750*100vw 0;
    }
  }
  
  &__serviceTtl{
    font-size: 24/1366*100vw;
    color: $main;
    font-weight: bold;
    @include sp{
      @include vw(36);
    }
  }
  
  
  &__serviceText{
    font-size: 15/1366*100vw;
    height: 90/1366*100vw;
    color: #2B2B2B;
    margin-top: 13/1366*100vw;
    @include sp{
      @include vw(30);
      width: 550/750*100vw;
      margin: 0 auto;
      height: auto;
      margin-top: 22/750*100vw;
    }
  }
  
  &__serviceLink{
    font-family: $font-family-barlow;
    font-size: 18/1366*100vw;
    font-weight: bold;
    position: relative;
    text-align: right;
    margin-top:  10/1366*100vw;
    @include sp{
      margin-top: 40/750*100vw;
    }
    a {
      position: relative;
      color: #2CA6E0;
      font-size: 18/1366*100vw;
      font-weight: 700;
      padding-right: 40/1366*100vw;
      transition: all 0.3s;
      @include sp {
        @include vw(36);
        padding-right: 80/750*100vw;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 60%;
        right: 6/1366*100vw;
        width: 25/1366*100vw;
        height: 10/1366*100vw;
        background: url(/assets/img/top/arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transition: all 0.3s;
        @include sp {
          right: 0;
          width: 38/750*100vw;
          height: 11/750*100vw;
          background-size: contain;
        }
      }
      @include pc {
        &:hover {
          &:before {
            right: 0;
          }
        }
      }
    }
  }
  
  //clients
  &__clients {
    position: relative;
    background: #fff;
    z-index: 2;
    @include sp {
      padding: 160/750*100vw 0 0;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 2090/1366*100vw;
      background: #E7F0F8;
      margin-top: 65vw;
      z-index: -1;
      @include sp {
        height: 3972/750*100vw;
        margin-top: 150vw;
      }
    }
  }
  
  &__clientsText{
    font-size: 22/1366*100vw;
    font-weight: bold;
    color: $main;
    margin-top: 25/1366*100vw;
    @include sp{
      @include vw(34);
      margin-top: 25/750*100vw;
    }
  }
  &__clientsInner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 960/1366*100vw;
    margin: 0 auto;
    z-index: 1;
    @include sp {
      width: 670/750*100vw;
    }
  }
  &__clientsLink {
    @include sp {
      margin-bottom: 20/750*100vw;
    }
    a {
      position: relative;
      color: #2CA6E0;
      font-size: 18px;
      font-weight: 700;
      padding-right: 80/1366*100vw;
      transition: all 0.3s;
      @include sp {
        @include vw(36);
        padding-right: 80/750*100vw;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        right: 6/1366*100vw;
        width: 35/1366*100vw;
        height: 10/1366*100vw;
        background: url(/assets/img/top/arrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transition: all 0.3s;
        @include sp {
          right: 0;
          width: 38/750*100vw;
          height: 11/750*100vw;
          background-size: contain;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: -10/1366*100vw;
        left: 0;
        width: 100%;
        height: 1px;
        background: #2CA6E0;
        @include sp {
          bottom: -20/750*100vw;
        }
      }
      @include pc {
        &:hover {
          &:before {
            right: 0;
          }
        }
      }
    }
  }
  &__clientsBlock {
    position: relative;
    display: block;
    width: 1163/1366*100vw;
    height: 426/1366*100vw;
    margin-left: auto;
    transition: all 0.3s;
    @include sp {
      width: 100%;
    }
    @include pc {
      &:hover {
        .p-top__clientsBlockDetailTag {
          &:before {
            right: 0;
          }
        }
      }
    }
    &--01 {
      background: url(/assets/img/top/clients_bg01.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20/1366*100vw;
      @include sp {
        background: none;
        margin-top: 67vw;
      }
    }
    &--02 {
      background: url(/assets/img/top/clients_bg02.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 49/1366*100vw;
      @include sp {
        background: none;
        margin-top: 12vw;
        z-index: 1;
      }
    }
    &--03 {
      background: url(/assets/img/top/clients_bg03.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20/1366*100vw;
      @include sp {
        background: none;
        margin-top: 67vw;
      }
    }
    &--04 {
      background: url(/assets/img/top/clients_bg04.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20/1366*100vw;
      @include sp {
        background: none;
        margin-top: 68vw;
      }
    }
    &--05 {
      background: url(/assets/img/top/clients_bg05.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20/1366*100vw;
      @include sp {
        background: none;
        margin-top: 66vw;
      }
    }
    &--06 {
      background: url(/assets/img/top/clients_bg06.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 20/1366*100vw;
      @include sp {
        background: none;
        margin-top: 67vw;
      }
    }
  }
  &__clientsBlockImg {
    @include pc {
      display: none;
    }
  }
  &__clientsBlockDetail {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 550px;
    width: 840/1366*100vw;
    padding: 35/1366*100vw;
    background: #FFF;
    @include sp {
      position: relative;
      min-width: auto;
      width: 695/750*100vw;
      height: 425/750*100vw;
      bottom: auto;
      right: auto;
      margin-top: -110/750*100vw;
      margin-left: auto;
      padding: 45/750*100vw 35/750*100vw;
    }
  }
  &__clientsBlockDetailInner {
    width: 600/1366*100vw;
    @include sp {
      width: 600/750*100vw;
    }
  }
  &__clientsBlockDetailArea {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15/1366*100vw;
    border-bottom: 1px solid #e1e1e1;
    @include sp {
      display: block;
      padding-bottom: 85/750*100vw;
    }
  }
  &__clientsBlockDetailTtl {
    color: #0F2C66;
    font-size: 17/1366*100vw;
    font-weight: 700;
    @include sp {
      @include vw(34);
    }
    &.fs15 {
      font-size: 15px;
      @include sp {
        @include vw(34);
      }
    }
  }
  &__clientsBlockDetailTag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    color:#2CA6E0;
    font-size: 12px;
    // margin-right: 30/1366*100vw;
    padding: 0 45/1366*100vw 0 0;
    font-size: 15/1366*100vw;
    font-weight: bold;
    position: relative;
    @include sp {
      display: inline-block;
      height: 50/750*100vw;
      @include vw(30);
      margin-top: 10/750*100vw;
      // padding: 8/750*100vw 15/750*100vw;
      float: right;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      right: 10/1366*100vw;
      width: 26/1366*100vw;
      height: 7/1366*100vw;
      background: url(/assets/img/top/arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 0.3s;
      transform: translateY(-50%);
      @include sp {
        right: -40/750*100vw;
        top: 20/750*100vw;
        width: 38/750*100vw;
        height: 11/750*100vw;
        background-size: contain;
        transform: none;
        padding: 0;
      }
      .p-top__clientsBlock--03 & {
        @include pc {
          top: 45%;
        }
      }
    }
  }
  &__clientsBlockDetailArrow {
    position: relative;
    width: 25px;
    right: 6px;
    transition: all 0.3s;
    @include sp {
      position: absolute;
      top: 50/705*100vw;
      right: 75/750*100vw;
      width: 38/750*100vw;
      height: 11/750*100vw;
    }
  }
  &__clientsBlockDetailList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 15/1366*100vw;
    @include pc {
      align-items: flex-start;
    }
    @include sp {
      margin-top: 35/750*100vw;
    }
    &:after {
      display: block;
      content: '';
      width: 33%;
      @include sp {
        width: 50%;
      }
    }
    &.dps {
      @include sp {
        display: block;
      }
    }
  }
  &__clientsBlockDetailItem {
    width: 100%;
    font-size: 15/1366*100vw;
    // @include indent;
    @include sp {
      width: 595/750*100vw;
      @include vw(28);
      margin-top: 40/750*100vw;
    }
  }
  //message
  &__message {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 960/1366*100vw;
    margin: 0 auto;
    z-index: 1;
    @include sp {
      display: block;
      width: 640/750*100vw;
    }
  }
  
  
  //news
  &__news {
    position: relative;
    padding: 0 0 160/1366*100vw;
    margin-top: 263/1366*100vw;
    background: #fff;
    @include sp {
      padding: 0 0 180/750*100vw;
      margin-top: 800/750*100vw;
    }
  }
  &__newsInner {
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 640/750*100vw;
    }
  }
  &__newsTtl {
    &--en {
      display: block;
      color: #2CA6E0;
      font-size: 25/1366*100vw;
      font-weight: 700;
      font-family: $font-family-barlow;
      @include sp {
        @include vw(32);
      }
    }
    &--ja {
      display: block;
      color: #0F2C66;
      font-size: 44/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      @include sp {
        @include vw(56);
      }
    }
  }
  &__newsList {
    width: 750/1366*100vw;
    background: #FFF;
    padding: 0 60/1366*100vw;
    @include sp {
      position: relative;
      top: auto;
      right: auto;
      width: 640/750*100vw;
      margin: 60/750*100vw auto 0;
      padding: 50/750*100vw 0;
      transform: none;
    }
  }
  &__newsListItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30/1366*100vw 0;
    border-top: 1px solid #e1e1e1;
    @include sp {
      display: block;
      padding: 30/750*100vw 0;
    }
    &:last-child {
      border-bottom: 1px solid #e1e1e1;
    }
    .day {
      display: block;
      color: #0F2C66;
      font-size: 15/1366*100vw;
      font-weight: 700;
      padding-right: 20/1366*100vw;
      @include sp {
        width: 100%;
        @include vw(28);
        padding-right: 0;
      }
    }
    .txt {
      display: block;
      width: 680/1366*100vw;
      font-size: 15/1366*100vw;
      @include sp {
        width: 100%;
        @include vw(28);
        margin-top: 10/750*100vw;
      }
    }
  }
}