@charset "utf-8";
.m-inner {
  position: relative;
  padding: 110/1366*100vw 0 160/1366*100vw;
  background: #E7F0F8;
  @include sp {
    padding: 160/750*100vw 0 200/750*100vw;
  }
  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 1003/1366*100vw;
    height: 316/1366*100vw;
    background: #FFF;
    @include sp {
      width: 585/750*100vw;
      height: 417/750*100vw;
    }
  }
}