@charset "utf-8";
.p-renewal {
  &__inner {
    position: relative;
    padding: 0 0 160/1366*100vw;
    background: #E7F0F8;
    @include sp {
      padding: 0 0 200/750*100vw;
    }
  }
  
  &__content {
    position: relative;
    background: #FFF;
    padding: 110/1366*100vw 0 180/1366*100vw 0;
    z-index: 1;
    @include sp {
      padding: 160/750*100vw 0;
    }
    &--bule{
      background: #E7F0F8;
      padding: 111/1366*100vw 0;
      @include sp{
        padding: 135/750*100vw 0;
      }
    }
  }
  &__contentInner{
    width: 960/1366*100vw;
    margin: 0 auto;
    @include sp{
      width: 638/750*100vw;
    }
  }
  &__lead{
    width: 984/1366*100vw;
    margin: 0 auto;
    @include sp{
      width: 638/750*100vw;
    }
  }
  &__leadText{
    font-size: 20/1366*100vw;
    font-weight: 700;
    color: #0F2C66;
    @include sp{
      @include vw(34);
      line-height: 1.8;
    }
  }
  &__ttl{
    width: 984/1366*100vw;
    font-size: 28/1366*100vw;
    font-weight: 700;
    color: #0F2C66;
    margin: 135/1366*100vw auto 0;
    @include sp{
      width: 638/750*100vw;
      @include vw(40);
      margin: 135/750*100vw auto 0;
    }
  }
  &__block{
    position: relative;
    z-index: 1;
    &::before{
      z-index: -1;
      content: "";
      display: block;
      width: 445/1366*100vw;
      height: 370/1366*100vw;
      position: absolute;
      top: 90/1366*100vw;
      right: 0;
      background: #0F2C66;
      @include sp{
        width: 424/750*100vw;
        height: 370/750*100vw;
        top: 730/750*100vw;
      }
    }
    &--reverse{
      &::before{
        z-index: -1;
        content: "";
        display: block;
        width: 445/1366*100vw;
        height: 370/1366*100vw;
        position: absolute;
        top: 90/1366*100vw;
        left: 0;
        background: #0F2C66;
        @include sp{
          width: 424/750*100vw;
          height: 370/750*100vw;
          top: 790/750*100vw;
        }
      }
    }
  }
  &__blockInner{
    width: 984/1366*100vw;
    margin: 70/1366*100vw auto 0;
    display: flex;
    @include sp{
      width: 638/750*100vw;
      display: block;
    }
    &--reverse{
      margin: 160/1366*100vw auto 0;
      @include sp{
        flex-direction: column-reverse;
        display: flex;
        margin: 175/750*100vw auto 0;
      }
    }
  }
  &__box{
    width: 440/1366*100vw;
    padding-right: 45/1366*100vw;
    @include sp{
      width: 638/750*100vw;
      padding-right: 0;
    }
    &--left{
      padding-right: 0;
      padding-left: 45/1366*100vw;
      @include sp{
        width: 638/750*100vw;
        padding-left: 0;
      }
    }
  }
  &__subTtl{
    font-size: 24/1366*100vw;
    font-weight: 700;
    color: #0F2C66;
    @include sp{
      @include vw(36);
    }
  }
  &__text{
    font-size: 17/1366*100vw;
    margin-top: 24/1366*100vw;
    color: #0F2C66;
    line-height: 1.8;
    @include sp{
      width: 638/750*100vw;
      margin-top: 30/750*100vw;
      @include vw(30);
    }
  }
  &__img{
    width: 560/1366*100vw;
    @include sp{
      width: 585/750*100vw;
      margin-top: 63/750*100vw;
    }
    &--sp{
      @include sp{
        margin-left: 50/750*100vw;
      }
    }
  }
  &__listItem{
    background: #fff;
    padding: 50/1366*100vw;
    margin-top: 15/1366*100vw;
    @include sp{
      padding: 90/750*100vw 45/750*100vw;
      margin-top: 25/750*100vw;
    }
    &:nth-child(1){
      margin-top: 25/1366*100vw;
      @include sp{
        margin-top: 40/750*100vw;
      }
    }
  }
  
  &__listTtl{
    font-size: 20/1366*100vw;
    font-weight: 700;
    color: #0F2C66;
    @include sp{
      @include vw(34);
    }
  }
  
  &__listText{
    font-size: 15/1366*100vw;
    margin-top: 30/1366*100vw;
    line-height: 1.8;
    @include sp{
      @include vw(30);
      margin-top: 30/750*100vw;
    }
  }
}