@charset "utf-8";
.p-company {
  &__inner {
    position: relative;
    padding: 110/1366*100vw 0 160/1366*100vw;
    background: #E7F0F8;
    @include sp {
      padding: 160/750*100vw 0 200/750*100vw;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 1003/1366*100vw;
      height: 316/1366*100vw;
      background: #FFF;
      @include sp {
        width: 586/750*100vw;
        height: 403/750*100vw;
      }
    }
  }
  &__block {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 960/1366*100vw;
    margin: 0 auto;
    transition: all 0.3s;
    z-index: 1;
    @include sp {
      display: block;
      width: 694/750*100vw;
      margin-left: auto;
      margin-right: inherit;
    }
    &.fdrr {
      flex-direction: row-reverse;
    }
    &:nth-of-type(n+2) {
      margin-top: 15/1366*100vw;
      @include sp {
        margin-top: 25/750*100vw;
      }
    }
  }
  &__blockImg {
    width: 480/1366*100vw;
    @include sp {
      width: 100%;
    }
  }
  &__blockArea {
    position: relative;
    width: 480/1366*100vw;
    padding: 60/1366*100vw;
    background: #FFF;
    @include pc {
      &:hover {
        .p-company__blockLink {
          span:before {
            right: 0;
          }
        }
      }
    }
    @include sp {
      width: 100%;
      padding: 60/750*100vw;
    }
  }
  &__blockTtl {
    &--en {
      display: block;
      color: #2CA6E0;
      font-size: 17/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      @include sp {
        @include vw(30);
      }
    }
    &--ja {
      display: block;
      color: #0F2C66;
      font-size: 24/1366*100vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      margin-top: 10/1366*10vw;
      @include sp {
        @include vw(44);
        margin-top: 10/750*10vw;
      }
    }
  }
  &__blockLink {
    margin-top: 80/1366*100vw;
    text-align: right;
    @include sp {
      margin-top: 60/750*100vw;
    }
    span {
      position: relative;
      color: #2CA6E0;
      font-size: 18/1366*100vw;
      font-weight: 700;
      padding-right: 60/1366*100vw;
      transition: all 0.3s;
      @include sp {
        @include vw(30);
        padding-right: 80/750*100vw;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        right: 6/1366*100vw;
        width: 35/1366*100vw;
        height: 10/1366*100vw;
        background: url(/assets/img/top/arrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transition: all 0.3s;
        @include sp {
          right: 0;
          width: 40/750*100vw;
          height: 11/750*100vw;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: -10/1366*100vw;
        left: 0;
        width: 100%;
        height: 1px;
        background: #2CA6E0;
        @include sp {
          bottom: -20/750*100vw;
        }
      }
    }
  }
}