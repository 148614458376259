@charset "utf-8";


.p-management {
    &__inner {
        position: relative;
        padding: 0 0 160/1366*100vw;
        background: #E7F0F8;
        @include sp {
          padding: 0 0 200/750*100vw;
        }
    }
    &__lead {
        position: relative;
        background: #FFF;
        padding: 110/1366*100vw 0;
        z-index: 1;
        @include sp {
          padding: 120/750*100vw 0;
        }
    }
    &__leadInner {
    width: 960/1366*100vw;
    margin: 0 auto;
        @include sp {
            width: 638/750*100vw;
        }
    }
    &__leadTtl {
        color: #0F2C66;
        font-size: 28/1366*100vw;
        font-weight: 700;
        line-height: 1.8;
        @include sp {
          @include vw(34);
        }
    }
    &__leadTxt {
        color: #0F2C66;
        font-size: 20/1366*100vw;
        line-height: 1.8;
        margin-top: 25/1366*100vw;
        @include pc {
            width: 90%;
        }
        @include sp {
          @include vw(34);
          margin-top: 40/750*100vw;
        }
    }
    
    &__leadLink{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 80/1366*100vw;
        @include sp{
            grid-template-columns: 1fr;
            gap: 15/1366*100vw;
        }
    }
    &__leadItem{
        a{
            position: relative;
            background: #E7F0F8;
            display: flex;
            align-items: center;
            padding: 30/1366*100vw;
            width: 235/1366*100vw;
            height: 64/1366*100vw;
            @include sp{
                width: 638/750*100vw;
                height: 112/750*100vw;
                padding: 40/750*100vw 55/750*100vw;
                justify-content: center;
            }
            &::before{
                content: '';
                display: inline-block;
                background: url('../img/service/management/arrow.svg')no-repeat center center;
                background-size: contain;
                width: 10/1366*100vw;
                height: 9/1366*100vw;
                position: absolute;
                top: 45%;
                right: 25/1366*100vw;
                @include sp{
                    width: 17/750*100vw;
                    height: 19/750*100vw;
                    right: 100/1366*100vw;
                }
            }
        }
        p{
            text-align: center;
            color: #0F2C66;
            font-size: 17/1366*100vw;
            font-weight: 700;
            @include sp{
                @include vw(34);
                justify-content: center;
            }
        }
        span{
            font-size: 17/1366*100vw;
            font-weight: 700;
            display: inline-block;
            padding-right: 20/1366*100vw;
            color: #0F2C66;
            @include sp{
                @include vw(34);
                position: absolute;
                left: 50/750*100vw
            }
        }
    }
    &__wapper{
        margin-top: 115/1366*100vw;
    }
    &__ttl{
        font-size: 24/1366*100vw;
        color: #0F2C66;
        font-weight: 700;
        @include sp{
            @include vw(36);
        }
        &--width{
            @include sp{
                width: 638/750*100vw;
                margin: 0 auto;
            }
        }
    }
    &__meritTtl{
        font-size: 20/1366*100vw;
        color: #fff;
        font-weight: 700;
        background: #6B89C7;
        padding: 10/1366*100vw 0;
        text-align: center;
        margin-top: 25/1366*100vw;
        @include sp{
            @include vw(36);
            padding: 20/750*100vw 0;
            margin-top: 50/750*100vw;
        }
    }
    &__block{
        width: 960/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
        &--width{
            @include sp{
                width: 100%;
            }
        }
        &.mt{
            margin: 80/1366*100vw auto 0;
            @include sp{
                margin: 125/750*100vw auto 0;
            }
        }
    }
    &__meritArea{
        display: grid;
        grid-template-columns: 170/1366*100vw 790/1366*100vw;
        height: 135/1366*100vw;
        margin-top: 5/1366*100vw;
        @include sp{
            grid-template-columns: 1fr;
            height: auto;
            margin-top: 15/750*100vw;
        }
    }
    &__meritSubTtl{
        background: #0F2C66;
        font-size: 17/1366*100vw;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        @include sp{
            @include vw(32);
            padding: 25/750*100vw 0;
        }
    }
    &__meritText{
        background: #fff;
        display: flex;
        align-items: center;
        @include sp{
            
        }
        p{
            font-size: 15/1366*100vw;
            padding: 0 32/1366*100vw;
            @include sp{
                @include vw(28);
                padding: 50/750*100vw 45/750*100vw;
            }
            span{
                font-size: 17/1366*100vw;
                font-weight: 700;
                color: #2B2B2B;
                display: block;
                margin-bottom: 10/1366*100vw;
                @include sp{
                    @include vw(28);
                    margin-bottom: 25/750*100vw;
                }
            }
        }
    }
    
    &__about{
        background: #FFFFFF;
        width: 960/1366*100vw;
        padding: 90/1366*100vw 0;
        margin-top: 25/1366*100vw;
        @include sp{
            width: 100%;
            padding: 80/750*100vw 0;
            margin-top: 45/750*100vw;
        }
    }
    &__aboutInner{
        width: 800/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 638/750*100vw;
        }
    }
    &__aboutWorkTtl{
        background: #0F2C66;
        color: #FFFFFF;
        font-size: 20/1366*100vw;
        font-weight: 700;
        text-align: center;
        padding: 10/1366*100vw 0;
        @include sp{
            @include vw(34);
            font-weight: bold;
            padding: 30/750*100vw 0;
        }
    }
    &__aboutWorkBg{
        background: #F2F3F7;
        padding: 25/1366*100vw 0;
        @include sp{
            padding: 35/750*100vw 0;;
        }
    }
    &__aboutWorkList{
        width: 616/1366*100vw;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        @include sp{
            width: 590/750*100vw;
        }
    }
    &__aboutWorkItem{
        border-radius: 50%;
        width: 174/1366*100vw;
        height: 174/1366*100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            width: 200/750*100vw;
            height: 200/750*100vw;
        }
        &:nth-child(1){
            background: #8AA1D0;
            @include sp{
                margin-top: 120/750*100vw;
            }
        }
        &:nth-child(2){
            background: #7091D5;
        }
        &:nth-child(3){
            background: #5887CC;
            @include sp{
                margin-top: 120/750*100vw;
            }
        }
    }
    &__aboutWorkArea{
        text-align: center;
    }
    &__aboutWorkImg{
        margin: 0 auto;
        &--01{
            width: 53/1366*100vw;
            height: 50/1366*100vw;
            @include sp{
                width: 60/750*100vw;
                height: auto;
            }
        }
        &--02{
            width: 73/1366*100vw;
            @include sp{
                width: 80/750*100vw;
            }
        }
        &--03{
            width: 43/1366*100vw;
            @include sp{
                width: 55/750*100vw;
            }
        }
    }
    &__aboutWorkText{
        font-size: 18/1366*100vw;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 1.3;
        margin-top: 10/1366*100vw;
        @include sp{
            @include vw(25);
            margin-top: 10/750*100vw;
        }
    }
    &__aboutArrow{
        width: 65/1366*100vw;
        margin: 17/1366*100vw auto;
        @include sp{
            width: 92/750*100vw;
        }
    }
    &__aboutSummary{
        font-size: 24/1366*100vw;
        color: #fff;
        font-weight: 700;
        background: #2CA6E0;
        padding: 12/1366*100vw 0;
        border-radius: 50px;
        margin-top: 20/1366*100vw;
        text-align: center;
        @include sp{
            @include vw(32);
            padding: 30/750*100vw 0;
            margin-top: 20/750*100vw;
        }
    }
    
    &__aboutText{
        font-size: 15/1366*100vw;
        margin-top: 50/1366*100vw;
        @include sp{
            @include vw(30);
            margin-top: 80/750*100vw;
        }
    }
    &__aboutBlock{
        margin-top: 50/1366*100vw;
        @include sp{
            margin-top: 111/750*100vw;
        }
    }
    &__aboutSubTtl{
        font-size: 17/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        border-bottom: solid 1px #E2E2E2;
        padding-bottom: 10/1366*100vw;
        @include sp{
            @include vw(34);
            padding-bottom: 15/750*100vw;
        }
    }
    &__aboutList{
        margin-top: 16/1366*100vw;
        @include sp{
            margin-top: 32/750*100vw;
        }
    }
    &__aboutItem{
        font-size: 15/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        margin-bottom: 15/1366*100vw;
        @include sp{
            @include vw(30);
            text-indent: -1em;
            padding-left: 1em;
            margin-bottom: 15/750*100vw;
        }
    }
    &__aboutFlex{
        display: flex;
        justify-content: space-between;
        margin-top: 40/1366*100vw;
        @include sp{
            display: block;
            margin-top: 60/750*100vw;
        }
    }
    &__aboutImg{
        width: 392/1366*100vw;
        @include sp{
            width: 638/750*100vw;
            margin-top: 50/750*100vw;
        }
        &.w100{
            width: 100vw;
            @include sp{
                width: 638/750*100vw;
            }
        }
    }
    &__aboutImgText{
        font-size: 15/1366*100vw;
        margin-top: 15/1366*100vw;
        text-align: center;
        @include sp{
            @include vw(26);
            margin-top: 32/750*100vw;
        }
    }
    
    &__concept{
        margin-top: 25/1366*100vw;
        @include sp{
            margin-top: 50/750*100vw;
        }
    }
    &__conceptItem{
        background: #FFFFFF;
        height: 121/1366*100vw;
        display: flex;
        align-items: center;
        padding: 0 45/1366*100vw;
        margin-top: 8/1366*100vw;
        @include sp{
            height: auto;
            padding: 55/750*100vw 45/750*100vw;
            margin-top: 15/750*100vw;
        }
    }
    &__conceptText{
        font-size: 20/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        @include sp{
            @include vw(32);
        }
    }
    &__plan{
        background: #fff;
        width: 960/1366*100vw;
        margin: 25/1366*100vw auto 0;
        @include sp{
            width: 100vw;
            margin: 50/750*100vw auto 0;
        }
    }
    &__planInner{
        width: 800/1366*100vw;
        margin: 0 auto;
        padding: 90/1366*100vw 0;
        @include sp{
            width: 638/750*100vw;
            padding: 80/750*100vw 0;
        }
    }
    &__planTtl{
        font-size: 24/1366*100vw;
        font-weight: 700;
        color: #0F2C66;
        @include sp{
            @include vw(36);
        }
    }
    &__planColumnTtl{
        background: #0F2C66;
        font-size: 20/1366*100vw;
        font-weight: 700;
        color: #fff;
        padding: 16/1366*100vw 18/1366*100vw;
        @include sp{
            @include vw(34);
            padding: 23/750*100vw 0;
            text-align: center;
        }
        span{
            width: 130/1366*100vw;
            display: inline-block;
            font-size: 15/1366*100vw;
            color: #0F2C66;
            background: #E7F0F8;
            border-radius: 80px;
            text-align: center;
            vertical-align: baseline;
            margin-right: 15/1366*100vw;
            padding: 5/1366*100vw 0;
            @include sp{
                width: 208/750*100vw;
                @include vw(26);
                margin-right: 20/750*100vw;
                padding: 5/750*100vw 0;
            }
        }
    }
    &__planColumn{
        background: #F2F3F7;
        margin-top: 15/1366*100vw;
        @include sp{
            margin-top: 25/750*100vw;
        }
    }
    &__planColumnBlock{
        display: flex;
        height: 150/1366*100vw;
        align-items: center;
        padding: 15/1366*100vw 30/1366*100vw;
        justify-content: space-between;
        @include sp{
            position: relative;
            height: 327/750*100vw;
            display: block;
            padding: 35/750*100vw 35/750*100vw;
        }
        &--03{
            display: block;
            height: auto;
            padding: 40/1366*100vw 0 60/1366*100vw 0;
        }
    }
    &__planColumnText{
        font-size: 20/1366*100vw;
        font-weight: bold;
        color: #0F2C66;
        @include sp{
            @include vw(32);
            text-align: center;
        }
    }
    &__planColumnArrow{
        width: 42/1366*100vw;
        @include sp{
            width: 60/750*100vw;
            transform: rotate(90deg);
            margin: 20/750*100vw auto ;
        }
    }
    &__planColumnEv{
        width: 340/1366*100vw;
        @include sp{
            width: 405/750*100vw;
        }
    }
    &__planColumnYear{
        width: 122/1366*100vw;
        height: 122/1366*100vw;
        background: #2CA6E0;
        border-radius: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            width: 158/750*100vw;
            height: 158/750*100vw;
            position: absolute;
            right: 30/750*100vw;
            bottom: 50/750*100vw;
        }
    }
    &__planColumnYearText{
        font-size: 18/1366*100vw;
        font-weight: bold;
        color: #fff;
        line-height: 1.0;
        @include sp{
            @include vw(22);
            line-height: 1.2;
        }
        .font{
            font-size: 14/1366*100vw;
            font-weight: bold;
            color: #fff;
            vertical-align: baseline;
            @include sp{
                @include vw(22);
            }
        }
        .num{
            font-size: 41/1366*100vw;
            font-weight: bold;
            color: #fff;
            vertical-align: baseline;
            @include sp{
                @include vw(49);
            }
        }
    }
    &__planColumnGraph{
        width: 610/1366*100vw;
        margin: 0 auto;
        @include sp{
            width: 530/750*100vw;
            margin: 65/750*100vw auto 0;
        }
    }
    &__planColumnGraphText{
        width: 648/1366*100vw;
        margin: 50/1366*100vw auto 0;
        @include sp{
            width: 515/750*100vw;
            margin: 60/750*100vw auto;
        }
    }
}