@charset "utf-8";
.p-message {
  .l-main__wrap {
    height: 305/1366*100vw;
    @include sp {
      height: 268/750*100vw;
    }
  }
  .l-main__kv {
    position: absolute;
    top: 70/1366*100vw;
    left: 0;
    right: 0;
    width: 1120/1366*100vw;
    margin: 0 auto;
    z-index: 1;
    @include sp {
      top: 80/750*100vw;
    }
  }
  .l-main__ttl {
    position: absolute;
    display: block;
    top: 50%;
    bottom: auto;
    left: 85/1366*100vw;
    width: auto;
    height: auto;
    background: none;
    transform: translateY(-50%);
    z-index: 1;
    @include sp {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      margin-top: 75/750*100vw;
      padding: 0;
      transform: none;
    }
  }
  
  &__inner {
    position: relative;
    padding: 315/1366*100vw 0 350/1366*100vw;
    background: #FFF;
    @include sp {
      padding: 500/750*100vw 0 350/750*100vw;
    }
  }
  &__wrap {
    width: 970/1366*100vw;
    margin: 0 auto;
    @include sp {
      width: 638/750*100vw;
    }
  }
  &__ttl {
    color: $main;
    font-size: 24/1366*100vw;
    font-weight: 700;
    @include sp {
      @include vw(36);
    }
  }
  &__txt {
    font-size: 15/1366*100vw;
    line-height: 1.8;
    @include sp {
      @include vw(30);
    }
  }
  
  &__name{
    float: right;
    font-size: 15/1366*100vw;
    margin-top: 110/1366*100vw;
    @include sp{
      @include vw(30);
    }
  }
  &__img {
    width: 188/1366*100vw;
    margin-top: 110/1366*100vw;
    margin-left: auto;
    @include sp {
      width: 358/750*100vw;
      margin-top: 75/750*100vw;
    }
  }
  
}